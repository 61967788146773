import Header from "../../../components/user_components/Header";
import InvestTabs from "../../../components/user_components/InvestTabs";
import box from "../../../assets/images/Box.png";
import React, { useContext, useEffect, useState } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import * as CurrencyFormat from "react-currency-format";
import moment from "moment";
import completed from "../../../assets/images/card-tick.svg";
import banner from "../../../assets/images/banner.png";
import { useNavigate } from "react-router-dom";
import InvestHeader from "../../../components/user_components/InvestHeader";
import MyInvestmentTabs from "../../../components/user_components/my-investment-tabs";
import { AppContext } from "../../../contexts/AppContext";
import DashboardLayout from "../../../components/user_components/layouts/dashboardLayout";
import InvestmentLayout from "../../../components/user_components/layouts/investmentLayout";

function Completed() {
  const value = useContext(AppContext)
  const [posts, setPosts] = useState();
  const [loading, setLoading] = useState(true);
  const [available, setAvailable] = useState(true);
  const navigate = useNavigate();

  async function fetchData() {
    const token = localStorage.getItem("user-token");
    // e.preventDefault();
    const response = await fetch(
      `${process.env.REACT_APP_MY_API_ENDPOINT}investment/fetch_completed_investment`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();
    //console.log(result.data);
    // alert(result.data[0].id);

    setPosts(result.data);
    if (result?.data.length === 0) {
      setAvailable(false);
      // alert("fetched Successfully");
    } else {
      setAvailable(true);
    }
    if (result?.status === "success") {
      setLoading(false);
    }
  }

  useEffect(() => {
    // activities();
    fetchData();
  }, []);

  return (
    <DashboardLayout>
      <InvestmentLayout>
        <div className="lg:hidden">
          <div className="py-8 px-4 bg-welcome text-dark text-lg font-semibold flex justify-between items-center">
            <h1 className="">Investments</h1>
            <button
              className="text-green text-sm font-inter"
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </button>
          </div>
          <div className=" p-5">
            <div className="flex items-center ">
              <img src={completed} alt="coins" className="mr-2" />
              <h1 className="text-sm text-title font-medium">
                Completed Investments
              </h1>
            </div>
          </div>
        </div>
        <MyInvestmentTabs />
        <div className="mb-8 ">
          {available ? (
            <>
              {loading ? (
                <div className="text-center px-20 py-40">
                  <ScaleLoader color="#008E10" height={50} width={6} />
                </div>
              ) : (
                <div className="overflow-auto px-4 bg-white">
                  <table className="w-full lg:table-auto">
                    <thead className="">
                      <tr className="text-left bg-dashbg">
                        <th className="py-2 text-head font-semibold text-sm  whitespace-nowrap pl-5 pr-20 lg:w-auto ">
                          Investments
                        </th>
                        <th className="py-2 pr-7 text-head font-semibold text-sm  whitespace-nowrap">
                          Duration
                        </th>
                        <th className="py-2 pr-7 text-head font-semibold text-sm  whitespace-nowrap">
                          Property Worth
                        </th>
                        <th className="py-2 pr-7 text-head font-semibold text-sm  whitespace-nowrap">
                          Amount Invested
                        </th>
                        <th className="py-2 pr-7 text-head font-semibold text-sm  whitespace-nowrap">
                          Amount Gained
                        </th>
                        <th className="py-2 pr-7 text-head font-semibold text-sm  whitespace-nowrap">
                          Status
                        </th>

                        {/* <th className="py-2 pr-7 text-head font-semibold text-sm">
                          Ends in
                        </th>
                        <th className="py-2 text-head font-semibold text-sm  whitespace-nowrap">
                          Action
                        </th> */}
                      </tr>
                    </thead>

                    <tr className="">
                      <td className="p-3"></td>
                    </tr>
                    {posts?.map((post) => (
                      <tr className="border-b" key={post.id}>
                        <td className=" py-8 text-footer font-bold text-sm whitespace-nowrap ">
                          <div className="ml-2 ">
                            <h1 className="mb-1">{post.product.title}</h1>
                            <h2 className="font-medium font-xs capitalize">
                              {post.product.category.product_category}
                            </h2>
                          </div>
                        </td>
                        <td className=" py-8 text-footer font-bold text-sm whitespace-nowrap">
                          <h1>{post.duration} Days</h1>
                        </td>
                        <td className="py-8 text-footer font-bold text-sm whitespace-nowrap">
                          <h1>
                            {value.globalState.currency === "USD" ? "$" : value.globalState.currency === "GBP" ? "£" : value.globalState.currency === "HX" ? "Hx" : value.globalState.currency === "NGN" ? "N" : null}
                            <CurrencyFormat
                              value={(post.product.cost / value.globalState.base_value).toFixed(2)}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </h1>
                        </td>
                        <td className=" py-8 text-footer font-bold text-sm whitespace-nowrap">
                          <h1>
                            {value.globalState.currency === "USD" ? "$" : value.globalState.currency === "GBP" ? "£" : value.globalState.currency === "HX" ? "Hx" : value.globalState.currency === "NGN" ? "N" : null}
                            <CurrencyFormat
                              value={(post.amount / value.globalState.base_value).toFixed(2)}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </h1>
                        </td>
                        <td className=" py-8 text-footer font-bold text-sm whitespace-nowrap">
                          <h1>
                            {value.globalState.currency === "USD" ? "$" : value.globalState.currency === "GBP" ? "£" : value.globalState.currency === "HX" ? "Hx" : value.globalState.currency === "NGN" ? "N" : null}
                            <CurrencyFormat
                              value={((
                                (post.amount * post.interest) /
                                100
                              ) / value.globalState.base_value).toFixed(2)}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </h1>
                          <h2 className="font-medium font-xs">
                            {post.interest}% Interest
                          </h2>
                        </td>
                        <td className=" py-8 text-footer font-bold text-sm whitespace-nowrap">
                          <button className="bg-status text-xs text-statustext w-28 h-9 rounded-full font-medium">
                            Completed
                          </button>
                        </td>
                      </tr>
                    ))}
                  </table>
                </div>
              )}
            </>
          ) : (
            <div className="flex flex-col items-center justify-center h-128">
              <div className="flex flex-col justify-center items-center">
                <img
                  src={box}
                  alt="No Completed investment"
                  className="-mr-10"
                />
              </div>
              <h1 className="font-semibold text-xs text-statustext text-center">
                Oh oh! You have no completed
                <br />
                investments at this time
              </h1>
            </div>
          )}
        </div>

      </InvestmentLayout>




    </DashboardLayout>
  );
}

export default Completed;
