import React from 'react'
import SubLayout from '../../../components/admin_components/layouts/SubLayout'
import PayoutRequests from '../../../components/admin_components/payout_requests'

function Payout() {
  return (
    <SubLayout title={"Payout"}>
      <PayoutRequests />
    </SubLayout>
  )
}

export default Payout
