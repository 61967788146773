import React from "react";
import InactiveList from "../../../components/admin_components/investment/InactiveList";
import InvestmentLayout from "../../../components/admin_components/layouts/investmentLayout";

function Inactive() {
  return (
    <InvestmentLayout>
      <InactiveList />
    </InvestmentLayout>
  );
}

export default Inactive;
