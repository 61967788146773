import React from "react";
import CreateMarchant from "../../../components/admin_components/CreateMerchant";
import DisbursedList from "../../../components/admin_components/DisbursedList";
import SubLayout from "../../../components/admin_components/layouts/SubLayout";

function Disbursed() {
  return (
    <SubLayout title="Merchants">

              <CreateMarchant />
              <DisbursedList />
        </SubLayout>
  );
}

export default Disbursed;
