import { Routes, Route, Link, useNavigate } from "react-router-dom";
import Transactions from "./transactions";
import InvestorsTransactions from "./transactions/InvestorsTransactions";
import Revenue from "./transactions/revenue/index";
import Staffs from "./staffs";
import Merchants from "./merchants";
import Investors from "./investors";
import RolesPermission from "./roles_and_permission";
import AdminSettings from "./settings";
import Banned from "./investors/Banned";
import PullFunds from "./merchants/PullFunds";
import Disbursed from "./merchants/Disbursed";
import AllInvestments from "./investment";
import Approved from "./investment/Approved";
import Inactive from "./investment/Inactive";
import CompletedInvestement from "./investment/CompletedInvestment";
import RelistedInvestments from "./investment/RelistedInvestments";
import Admin_Dashboard from "./dashboard";
import { useEffect } from "react";
import Payout from "./payout";
import Suspended from "./payout/suspended";


const Index = () => {
    return (
        <>
            <Routes>
                <Route path="/transactions" element={<Transactions />} />
                <Route
                    path="/transactions/investors"
                    element={<InvestorsTransactions />}
                />
                <Route path="/transactions/revenue" element={<Revenue />} />
                <Route path="/staffs" element={<Staffs />} />
                <Route path="/merchants" element={<Merchants />} />
                <Route path="/investors" element={<Investors />} />
                <Route path="/roles-permission" element={<RolesPermission />} />
                <Route path="/settings" element={<AdminSettings />} />
                <Route path="/investors/banned" element={<Banned />} />
                <Route
                    path="/merchants/pull-funds-request"
                    element={<PullFunds />}
                />
                <Route
                    path="/merchants/disbursed-funds"
                    element={<Disbursed />}
                />
                <Route path="/investments" element={<AllInvestments />} />
                <Route path="/investments/approved" element={<Approved />} />
                <Route path="/investments/inactive" element={<Inactive />} />
                <Route
                    path="/investments/completed"
                    element={<CompletedInvestement />}
                />
                <Route
                    path="/investments/relisted"
                    element={<RelistedInvestments />}
                />
                <Route path="/dashboard" element={<Admin_Dashboard />} />
                <Route path="/payout" element={<Payout />} />
                <Route path="/payout/suspended" element={<Suspended />} />

            </Routes>
        </>
    )
}

export default Index