import React, { useContext, useEffect, useState } from "react";
import box from "../../../assets/images/Artwork.svg";
import bidderss from "../../../assets/images/bidders.svg";
import { FaAngleRight } from "react-icons/fa";

import arrow from "../../../assets/images/arrow.svg";
import moment from "moment";
import card from "../../../assets/images/card.svg";
import { toast } from "react-toastify";
import * as CurrencyFormat from "react-currency-format";
import user from "../../../assets/images/user_icon.png";
import { motion } from "framer-motion";
import ScaleLoader from "react-spinners/ScaleLoader";
import DashboardLayout from "../../../components/user_components/layouts/dashboardLayout";
import { AppContext } from "../../../contexts/AppContext";

function Bids() {
  const value = useContext(AppContext)
  const [available, setAvailable] = useState(true);
  const [loading, setLoading] = useState(true);
  const [click, setClick] = useState(false);
  // const [bidders, setBidders] = useState();
  const [bids, setBids] = useState();
  const [bidID, setBidID] = useState();
  const [bidTitle, setBidTitle] = useState()

  async function fetchInvestment() {
    const token = localStorage.getItem("user-token");
    // e.preventDefault();
    const response = await fetch(
      `${process.env.REACT_APP_MY_API_ENDPOINT}exchange/fetch_investment_bids`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();
    //console.log(result.data);
    // alert(result.data.name);
    setBids(result?.data);
    if (result?.data.length === 0) {
      setAvailable(false);
    } else {
      setAvailable(true);
    }
    if (result?.status === "success" && result?.data.length > 0) {
      setLoading(false);
      setBidID(result?.data[0].id);
      setBidTitle(result?.data[0].investment.product.title)
    }
  }

  const [disable, setDisable] = useState(false)
  async function approveBid(id) {
    const payLoad = {
      id: id,
    };
    setDisable(true)
    const token = localStorage.getItem("user-token");
    const response = await fetch(
      `${process.env.REACT_APP_MY_API_ENDPOINT}exchange/approve_bid`,
      {
        method: "POST",
        body: JSON.stringify(payLoad),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();
    console.log(result);
    // alert(productId);

    if (result?.status === "success") {
      //console.log(result.data);
      setWarning(false)
      setDisable(false)
      toast.success(`${result.message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      fetchInvestment();
      // setDetails(false);
    } else {
      if (result.status === "error") {
        //console.log(result.data);
        toast.error(`${result.message}`, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setDetails(false);
      }
    }
  }

  async function declineBid(id) {
    const payLoad = {
      id: id,
    };

    const token = localStorage.getItem("user-token");
    const response = await fetch(
      `${process.env.REACT_APP_MY_API_ENDPOINT}exchange/decline_bid`,
      {
        method: "POST",
        body: JSON.stringify(payLoad),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();
    console.log(result);
    // alert(productId);

    if (result?.status === "success") {
      //console.log(result.data);
      toast.success(`${result.message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      fetchInvestment();
      setDecline(false);
    } else {
      if (result.status === "error") {
        //console.log(result.data);
        toast.error(`${result.message}`, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // setDetails(false);
      }
    }
  }

  useEffect(() => {
    fetchInvestment();
    // console.log(groupListWithTheSameId(bidders));
  }, []);

  const [details, setDetails] = useState();
  const [visible, setVisible] = useState(false);
  const [warning, setWarning] = useState(false);
  const [decline, setDecline] = useState(false);
  const [bidderID, setBidderID] = useState();

  const getBiddersCount = (title) => {
    const filteredData = bids?.filter((item) => item.investment && item.investment.product && item.investment.product.title === title)
    return filteredData.length
  }

  return (
    <DashboardLayout>
      {warning && (
        <div className="flex items-center justify-center top-0 bottom-0 left-0 right-0 bg-overlay fixed z-50">
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.3,
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                delay: 0.5,
              },
            }}
            className={`lg:w-128 w-11/12 m-auto bg-white rounded-xl fixed border-green p-6 text-center ${warning ? "block" : "hidden"
              } `}
          >
            <div>
              <h1 className="lg:font-bold font-semibold text-neutral text-3xl">
                Confirm
              </h1>
            </div>
            <div className="font-semibold lg:text-base text-sm text-neutral my-8">
              <p>
                Are you sure you want to accept this bid?
              </p>
            </div>
            <div className="flex justify-between">
              <button
                className="border text-neutral bg-dashbg text-sm lg:text-base rounded-full w-36 h-10 lg:w-44 lg:h-12"
                onClick={() => {
                  // closeWarning(!closeWarning);
                  setWarning(!warning);
                  // closeModal(false);
                  // window.location = "/investment";
                  // setIsClick(!isClick);
                }}
              >
                Cancel
              </button>
              <button
                disabled={disable}
                className=" text-sm lg:text-base rounded-full w-36 h-10 lg:w-44 lg:h-12 text-dashbg bg-green disabled:cursor-not-allowed"
                onClick={() => {
                  // inherit(postID);
                  approveBid(bidderID)
                }}
              >
                Accept
              </button>
            </div>
          </motion.div>
        </div>
      )}
      {decline && (
        <div className="flex items-center justify-center top-0 bottom-0 left-0 right-0 bg-overlay fixed z-50">
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.3,
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                delay: 0.5,
              },
            }}
            className={`lg:w-128 w-11/12 m-auto bg-white rounded-xl fixed border-green p-6 text-center ${decline ? "block" : "hidden"
              } `}
          >
            <div>
              <h1 className="lg:font-bold font-semibold text-neutral text-3xl">
                Confirm
              </h1>
            </div>
            <div className="font-semibold lg:text-base text-sm text-neutral my-8">
              <p>
                Are you sure you want to decline this bid?
              </p>
            </div>
            <div className="flex justify-between">
              <button
                className="border text-neutral bg-dashbg text-sm lg:text-base rounded-full w-36 h-10 lg:w-44 lg:h-12"
                onClick={() => {
                  // closeWarning(!closeWarning);
                  setDecline(!decline);
                  // closeModal(false);
                  // window.location = "/investment";
                  // setIsClick(!isClick);
                }}
              >
                Cancel
              </button>
              <button
                className=" text-sm lg:text-base rounded-full w-36 h-10 lg:w-44 lg:h-12 text-dashbg bg-green"
                onClick={() => {
                  // inherit(postID);
                  // approveBid(bidderID)
                  declineBid(bidderID)
                }}
              >
                Decline
              </button>
            </div>
          </motion.div>
        </div>
      )}
      <div className="bg-white lg:h-auto lg:p-10 w-full rounded-lg">
        <div className="lg:hidden py-8 px-4 bg-welcome text-dark text-lg font-semibold flex justify-between items-center">
          <h1 className="">Investment bidders</h1>
        </div>
        <div className=" pb-10 flex justify-between items-center">
          <h1 className="hidden lg:block text-modal text-2xl font-semibold ">
            Investment Bid
          </h1>
          {available && (
            <div className="lg:relative absolute top-7 right-5 lg:w-48 w-36">
              <button
                className="bg-listed flex text-white text-xs rounded px-3 lg:px-6 py-3 items-center w-full"
                onClick={() => {
                  setClick(!click);
                }}
              >
                <span className="mr-1 truncate">
                  {bids?.length === 0
                    ? bids[0].investment.product.title
                    : bids
                      ?.filter((bid) => bid.id === bidID)
                      .map((bid) => <>{bid.investment.product.title}</>)}
                </span>
                <img src={arrow} alt="arrow-icon" />
              </button>

              <div
                className={`absolute px-4 pb-4 w-80 lg:w-96 text-neutral right-0 top-20 -mt-2 rounded-xl shadow-2xl bg-listed text-left invisible flex flex-col items-center duration-300 z-10 ${click ? "show-note !top-14" : "remove-note"
                  }`}
              >
                <div className="arr relative w-full"></div>

                {[...new Set(bids?.map(item => item.investment.product.title))].map((title) => (
                  <div
                    className="py-3 w-full pr-20 border-b border-tokentext cursor-pointer hover:opacity-70"
                    onClick={() => {
                      setClick(!click);
                      const matchingBids = bids.filter(item => item.investment.product.title === title);
                      if (matchingBids.length > 0) {
                        setBidID(matchingBids[0].id);
                        setBidTitle(matchingBids[0].investment.product.title);
                      }
                    }}
                    key={title}
                  >
                    <h1 className="text-more font-semibold mb-1.5">
                      {title}
                    </h1>
                    <h1 className="text-sm text-footer font-normal">
                      {getBiddersCount(title)} Bidder{getBiddersCount(title) > 1 ? "s" : ""}
                    </h1>
                  </div>
                ))}
              </div>
              <div
                className={`fixed top-0 left-0 bottom-0 right-0 cursor-pointer ${click ? "show-note" : "remove-note"
                  }`}
                onClick={() => setClick(!click)}
              ></div>
            </div>
          )}
        </div>

        <div className="lg:mb-8 mb-5 mine investlists">
          {available ? (
            <>
              {loading ? (
                <div className="text-center px-20 py-40">
                  <ScaleLoader color="#008E10" height={50} width={6} />
                </div>
              ) : (
                <>
                  {bids
                    ?.filter((bid) => bid.id === bidID)
                    .map((bid, index) => (
                      <div key={index} className="">
                        <div className="flex items-center flex-col lg:flex-row justify-between bg-banner p-5 lg:p-12 lg:py-14 lg:bids lg:w-full w-11/12 rounded-lg lg:rounded-none m-auto mb-10 lg:mb-auto">
                          <div className="lg:w-1/2 w-full flex justify-between items-center mb-7 lg:mb-auto lg:block">
                            <h1 className="text-white font-semibold lg:text-3xl text-sm lg:mb-5">
                              {bid.investment.product.title}
                            </h1>
                            <h1 className="font-semibold uppercase lg:text-sm text-tiny bg-media p-1 px-2 text-dashbg w-fit h-fit rounded">
                              {
                                bid.investment.product.category
                                  .product_category
                              }
                            </h1>
                          </div>
                          <div className=" flex justify-between items-center w-full lg:w-1/2">
                            <div className=" flex font-inter border-r border-card w-1/2 lg:py-2">
                              <div className="mr-2">
                                <img
                                  src={bidderss}
                                  alt="bidders-icon"
                                  className="w-10 lg:w-12"
                                />
                              </div>
                              <div>
                                <h1 className="font-bold lg:text-2xl text-lg text-white">
                                  {getBiddersCount(bid.investment.product.title)}
                                </h1>
                                <h1 className="text-svg lg:text-xs text-tiny">
                                  Total Bidder{getBiddersCount(bid.investment.product.title) > 1 ? "s" : ""}
                                </h1>
                              </div>
                            </div>
                            <div className=" lg:pl-5 flex items-center">
                              <div className="mr-2">
                                <img
                                  src={card}
                                  alt="bidders-icon"
                                  className="w-10 lg:w-12"
                                />
                              </div>
                              <div>
                                <h1 className="text-svg lg:text-xs text-tiny">
                                  {bid.investment.amount / 50000} Hx
                                </h1>

                                <h1 className="font-semibold lg:text-2xl text-lg text-white">
                                  {value.globalState.currency === "USD" ? "$" : value.globalState.currency === "GBP" ? "£" : value.globalState.currency === "HX" ? "Hx" : value.globalState.currency === "NGN" ? "N" : null}
                                  <CurrencyFormat
                                    value={(bid.investment.amount / value.globalState.base_value).toFixed(2)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />
                                </h1>
                                <h1 className="text-svg lg:text-xs text-tiny">
                                  Investment worth
                                </h1>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className=" mt-10 lg:block hidden">
                          <table className=" w-full">
                            <thead className="">
                              <tr className="text-left bg-dashbg">
                                <th className="py-1 text-head font-semibold text-sm pl-9 w-60">
                                  Investors
                                </th>
                                <th className="py-1 text-head font-semibold text-sm ">
                                  Product Name
                                </th>
                                <th className="py-1 text-head font-semibold text-sm ">
                                  Amount Invested
                                </th>
                                <th className="py-1 pr-7 text-head font-semibold text-sm ">
                                  Date
                                </th>
                                <th className="py-1 pr-7 text-head font-semibold text-sm ">
                                  Time
                                </th>
                                <th className="py-1 pr-7 text-head font-semibold text-sm ">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            {bids?.filter(bid => bid.investment.product.title === bidTitle).map((bidder) => (
                              <tr className="border-b " key={bidder.id}>
                                <td className="py-8 pl-9 flex items-center">
                                  <div className="mr-2">
                                    <img
                                      src={user}
                                      alt="Investment Icon"
                                      className="w-8"
                                    />
                                  </div>
                                  <div className=" w-40">
                                    <h1 className="font-normal text-statustext text-sm truncate">
                                      <span title="investors name">
                                        {bidder.user.name}
                                      </span>
                                    </h1>
                                  </div>
                                </td>
                                <td className="py-8">
                                  <h1 className="font-normal text-statustext text-sm">
                                    {bidder.investment.product.title}
                                  </h1>
                                </td>
                                <td className="py-8">
                                  <h1 className="font-normal text-statustext text-sm">
                                    {value.globalState.currency === "USD" ? "$" : value.globalState.currency === "GBP" ? "£" : value.globalState.currency === "HX" ? "Hx" : value.globalState.currency === "NGN" ? "N" : null}
                                    <CurrencyFormat
                                      value={(bidder.amount / value.globalState.base_value).toFixed(2)}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />
                                  </h1>
                                </td>
                                <td className="py-8">
                                  <h1 className="font-normal text-statustext text-sm">
                                    {moment(bidder.created_at).format(
                                      "MMM DD, yyyy"
                                    )}
                                  </h1>
                                </td>
                                <td className="py-8">
                                  <h1 className="font-normal text-statustext text-sm">
                                    {moment(bidder.created_at).format("LT")}
                                  </h1>
                                </td>

                                <td className="py-3">
                                  <button
                                    className="font-medium text-xs font-inter text-blue py-2 px-2 border-r"
                                    onClick={(e) => {
                                      setWarning(true)
                                      setBidderID(bidder.id)
                                      // approveBid(bidderID)
                                    }}
                                    disabled={disable}
                                  >

                                    Approve
                                  </button>
                                  <button
                                    className="font-medium text-xs font-inter text-red py-1 px-2"
                                    onClick={() => {
                                      setBidderID(bidder.id)
                                      setDecline(true)
                                      // declineBid(bidder.id)
                                    }}
                                  >
                                    Decline
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </table>
                        </div>
                        <div className="mb-20 lg:hidden">
                          {bids?.map((bidder) => (
                            <div
                              key={bidder.id}
                              className="bids-shadow border-b w-11/12 m-auto rounded-lg my-4 p-5 "
                            >
                              <div
                                className="flex justify-between"
                                onClick={() => {
                                  setDetails(bidder.id);
                                  setVisible(!visible);
                                }}
                              >
                                <div className="flex items-center">
                                  <img
                                    src={user}
                                    alt="Investment Icon"
                                    className="w-8 mr-2 "
                                  />
                                  <h1 className="font-medium text-vestabs text-sm truncate">
                                    <span title="investors name">
                                      {bidder.user.name}
                                    </span>
                                  </h1>
                                </div>
                                <div className="flex items-center">
                                  <div className="mr-2 text-right">
                                    <h1 className="font-semibold text-amount text-sm">
                                      N
                                      <CurrencyFormat
                                        value={bidder.amount}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />
                                    </h1>
                                    <h1 className="font-normal text-amounttiny text-tiny">
                                      Amount
                                    </h1>
                                  </div>
                                  <div className="text-navbar text-lg">
                                    <FaAngleRight />
                                  </div>
                                </div>
                              </div>
                              {details === bidder.id && (
                                <div>
                                  <div className="bg-details my-4 p-2 rounded">
                                    <div>
                                      <h1 className="text-xs text-statustext font-semibold text-center">
                                        Product tile:{" "}
                                        <span className="font-medium">
                                          {bidder.investment.product.title}
                                        </span>
                                      </h1>
                                    </div>
                                    <div className="flex justify-between mt-2">
                                      <h1 className="text-xs text-statustext font-semibold">
                                        Date:{" "}
                                        <span className="font-medium">
                                          {moment(bidder.created_at).format(
                                            "MMM DD, yyyy"
                                          )}
                                        </span>
                                      </h1>
                                      <h1 className="text-xs text-statustext font-semibold">
                                        Time:{" "}
                                        <span className="font-medium">
                                          {moment(bidder.created_at).format(
                                            "LT"
                                          )}
                                        </span>
                                      </h1>
                                    </div>
                                  </div>
                                  <div className="flex justify-between ">
                                    <button
                                      className="bg-red rounded-full px-9 py-2 text-white"
                                      onClick={() => {
                                        setBidderID(bidder.id)
                                        setDecline(true)
                                        // declineBid(bidder.id)
                                      }}
                                    >
                                      Decline
                                    </button>
                                    <button
                                      className="border rounded-full border-green text-green font-bold px-9 py-2"
                                      onClick={(e) => {
                                        setWarning(true)
                                        // approveBid(bidder.id)
                                      }}
                                      disabled={disable}

                                    >
                                      Approve
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                </>
              )}
            </>
          ) : (
            <div className="flex flex-col items-center justify-center lg:h-128 h-p">
              <div className="flex justify-center items-center">
                <img
                  src={box}
                  alt="No relisted investment"
                  className="w-32 lg:w-44"
                />
              </div>
              <h1 className="font-semibold lg:text-2xl text-sm text-statustext text-center">
                No bids placed yet
              </h1>
            </div>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
}

export default Bids;
