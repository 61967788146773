import React from 'react'
import Header from '../Header'

function DashboardLayout({children}) {
  return (
    <div>
      <Header />
      <div className='lg:py-20'>
        <div className='container max-w-[1229px] mx-auto bg-dashbg rounded-[10px] lg:py-8 lg:px-4'>
            {children}
        </div>
      </div>
      <div className="footer">
        <h1 className="text-base font-semibold text-footer">
          © 2022 HxAfrica. All rights reserved.
        </h1>
      </div>

    </div>
  )
}

export default React.memo(DashboardLayout)