import React, { useEffect } from "react";
import Header from "../../../components/admin_components/Admin_header";
import SideBar from "../../../components/admin_components/SideBar";
import InvestorsActivities from "../../../components/admin_components/InvestorsActivities";
import SubLayout from "../../../components/admin_components/layouts/SubLayout";

function InvestorsTransactions() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <SubLayout title={"Transactions"}>
      <InvestorsActivities />
    </SubLayout>
  );
}

export default InvestorsTransactions;
