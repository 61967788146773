import React, { useEffect, useState } from "react";
import totalPercentage from "../../../../assets/images/gross.svg";
import * as CurrencyFormat from "react-currency-format";
import totalAmount from "../../../../assets/images/totalProducts.svg";
import transaction from "../../../../assets/images/transactions.svg";
import { toast } from "react-toastify";
import { Link, NavLink } from "react-router-dom";

function Overview({ totalTransaction, totalInvestment, transactions, percentage }) {
    const overview = [
        {
            title: "total transactions",
            figure: transactions ?? 0,
            icon: transaction
        },
        {
            title: "Total Transaction Amount",
            figure: totalInvestment ?? 0,
            icon: totalAmount
        }
        , {
            title: "Total Percentage Charge",
            figure: totalTransaction ?? 0,
            icon: totalPercentage
        }
    ]
    return (
        <div className="flex flex-col gap-4">
            <div className="flex items-center text-sm mar rounded-lg text-footer bg-white px-9 ">
                <Link to="/admin/transactions">
                    <div
                        className={`font-normal px-1 py-2.5 border-b-4 border-transparent hover:text-dark `}
                    >
                        <h1>Merchants </h1>
                    </div>
                </Link>
                <span className="w-7"> </span>
                <NavLink to="/admin/transactions/investors">
                    <div className="font-normal px-1 py-2.5 border-b-4 border-transparent hover:text-dark ">
                        <h1>Investors </h1>
                    </div>
                </NavLink>
                <span className="w-7"> </span>
                <NavLink to="/admin/transactions/revenue">
                    <div className="font-normal px-1 py-2.5 border-b-4 border-transparent hover:text-dark ">
                        <h1>Revenue </h1>
                    </div>
                </NavLink>
            </div>
            <div className="grid grid-cols-3 gap-4">
                {overview.map((overview, index) => (
                    <div key={index} className="flex gap-3 py-10 px-10 rounded-[10px] bg-white">
                        <div className="shrink-0">
                            <img src={overview.icon} alt="total" />
                        </div>
                        <div>
                            <h1 className="text-earnings font-medium text-xs mb-1">
                                {overview.title}
                            </h1>
                            <h1 className="text-dark font-medium text-2xl">
                                <CurrencyFormat
                                    value={overview.figure}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                />
                            </h1>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Overview;
