import React from 'react'
import Header from '../Admin_header'
import SideBar from '../SideBar'

function AdminDashboardLayout({ children }) {
  return (
    <div className="h-screen bg-dashbg font-family">
      <Header />
      <div className="grid grid-cols-5 h-full">
        <div className="col-span-1 border-r bg-white relative">
          <SideBar />
        </div>
        <div className='col-span-4 container mx-auto flex-grow p-5 mb-20'>
          {children}
        </div>
      </div>
    </div>
  )
}

export default AdminDashboardLayout
