
import { FaRegEyeSlash } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import logo from "../assets/images/HXafrica Logo 1.svg";
import GoogleAuth from "./googleAuth";



function Login() {
  // console.log(loading.env.REACT_APP_MY_API_ENDPOINT);

  const {
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const endPoint = process.env.REACT_APP_MY_API_ENDPOINT;
  async function login(e) {
    // console.log(formData);
    // e.preventDefault();
    setLoading(true);

    const loginLoad = {
      email: loginData.email,
      password: loginData.password,
    };
    try {
      const response = await fetch(`${endPoint}login`, {
        method: "POST",
        body: JSON.stringify(loginLoad),
        headers: {
          "Content-type": "application/json",
        },
      });
      const result = await response.json();
      // console.log(result?.status);
      if (result?.status === "success") {
        localStorage.setItem("user-token", result?.data.api_token);
        localStorage.setItem("user-email", result?.data.email);
        localStorage.setItem("name", result?.data.name);
        localStorage.setItem("user-name", result?.data.username);
        localStorage.setItem("user-phone", result?.data.phone);
        // localStorage.setItem("user-dob", result?.data.investor.dob);
        // localStorage.setItem("user-city", result?.data.investor.city);
        // localStorage.setItem("user-state", result?.data.investor.state);
        // localStorage.setItem("user-address", result?.data.investor.address);
        localStorage.setItem("user-id", result?.data.id);
        localStorage.setItem("bvn", result?.data.is_bvn_verified);
        localStorage.setItem("role", result?.data.role);
        // Route to Dashbaord
      } else {
        if (result.status === "error") {
          // setError(result.data);
          console.log(result);
          setLoading(false);
          // alert(result.message);
          toast.error(`${result.message}`, {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }

      if (result?.data.is_ban === "1") {
        toast.error(`Sorry you can't log in, please contact Support`, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate("/login");
        setLoading(false);
      } else if (result.data.role === "investor") {
        localStorage.setItem("currency", result?.data.investor.currency);
        toast.success(`${result.message}`, {
          position: "top-right",
          autoClose: 300,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        fetchRates();
      } else if (result.data.role === "staff") {
        toast.success(`${result.message}`, {
          position: "top-right",
          autoClose: 300,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate("/admin/dashboard");
      } else {
        toast.success(`${result.message}`, {
          position: "top-right",
          autoClose: 300,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate("/admin/dashboard");
      }
    } catch (err) {
      console.error(err)
      setLoading(false);
    }
  }

  const fetchRates = async () => {
    const token = localStorage.getItem("user-token");
    const userCurrency = localStorage.getItem("currency")
    const response = await fetch(`${process.env.REACT_APP_MY_API_ENDPOINT}wallet/fetch_rates`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
    const result = await response.json();
    if (result.status === "success") {
      localStorage.setItem("base", JSON.stringify(result?.data.find((data) => data.currency === userCurrency)));

      // Navigate to "/dashboard" route
      navigate("/dashboard");
    } else {
      // Display error toast
      toast.error("Server Error", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="font-family bg-white ">

      <div className="lg:flex justify-between 2xl:h-screen">
        <div className="bg-[url('../src/assets/images/hxOnboard.jpg')] relative before:bg-green before:mix-blend-hard-light before:absolute before:top-0 before:left-0 before:bottom-0 before:right-0 before:content-[' '] before:-z-10 z-10 lg:w-1/2 bg-cover bg-center hidden lg:flex items-center">
          <div className="absolute top-0 left-0 pt-[77.49px] px-5 xl:pl-[90px]">
            <Link to="/">
              <img src={logo} className="" alt="logo" />
            </Link>

          </div>
          <div className="text-white px-5 xl:px-[90px]">
            <h1 className="font-bold text-[80px] font-body">Magnifying Technology</h1>
            <h1 className="font-normal text-2xl">Changing the world of technology with the brightest of ideas and people centered community.</h1>

          </div>
        </div>
        <div className="xl:px-36 px-10 lg:pt-52 pt-14 pb-5 w-full lg:w-1/2">
          <div className="lg:w-[345px] mx-auto">
            <form className="form" onSubmit={handleSubmit(login)} >
              <div className="">
                <div className="">
                  <h1 className="page-text !text-center">Welcome Back</h1>
                  <p className=" lg:text-base text-sm mb-10 font-normal text-center ">Kindly Login to your account to continue enjoying all benefits.</p>
                </div>

                <div className="mb-5">
                  {/* <GoogleLogin
                    onSuccess={credentialResponse => {
                      console.log(credentialResponse);
                    }}
                    onError={() => {
                      console.log('Login Failed');
                    }}
                  /> */}

                  <GoogleAuth title="Continue with google" />
                  <h1 className="text-center">Or</h1>

                </div>
                <div className="input">
                  <label className="">Email Address</label>
                  <input
                    required
                    type="email"
                    placeholder="enter email address"
                    className={`box ${errors.password &&
                      "focus:border-red focus:ring-red border-red"
                      }`}
                    // {...register("email", {
                    //   required: "email is required",
                    //   pattern: {
                    //     value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                    //     message: "Please enter a valid email.",
                    //   },
                    // })}
                    value={loginData.email}
                    onChange={(event) =>
                      setLoginData({ ...loginData, email: event.target.value })
                    }
                  />
                  {errors.email && (
                    <span className="text-red text-xs">
                      {" "}
                      {errors.email.message}
                    </span>
                  )}
                </div>

                <div className="input relative">
                  <label>Password</label>
                  <input
                    required
                    type="password"
                    placeholder="enter password"
                    className={`box ${errors.password &&
                      "focus:border-red focus:ring-red border-red"
                      }`}
                    // {...register("password", {
                    //   required: "Password is required",
                    // })}
                    id="pass"
                    value={loginData.password}
                    onChange={(event) =>
                      setLoginData({ ...loginData, password: event.target.value })
                    }
                  />
                  {errors.password && (
                    <span className="text-red text-xs">
                      {" "}
                      {errors.password.message}
                    </span>
                  )}
                  <FaRegEyeSlash
                    className="text-grey absolute top-11 right-4 cursor-pointer"
                    onClick={() => {
                      let x = document.getElementById("pass");
                      if (x.type === "password") {
                        x.type = "text";
                      } else {
                        x.type = "password";
                      }
                    }}
                  />
                </div>

                <div className=" flex items-start">
                  <Link to="/account-recovery">
                    <p className="text-xs tracking-wide font-semibold">Forgot Password?</p>
                  </Link>
                </div>

                <div
                  className="bg-green text-white flex justify-center items-center w-full rounded-xl mt-6 font-medium cursor-pointer"
                // onClick={login}
                >
                  {/* value={loading ? <TbLoader className="animate-spin" /> : "Log in"} */}

                  <input
                    type="submit"
                    className=" cursor-pointer w-full p-3 outline-none"
                    value={loading ? `Processing ...` : "Log in"}
                  />
                </div>
              </div>
            </form>
            <p className="text-sm font-medium text-sec mt-8 mb-48 text-center md:text-left">
              You are new ?{" "}
              <Link to="/sign-up">
                <span className="text-green">sign up</span>
              </Link>
            </p>
          </div>

        </div>

      </div>
    </div>
  );
}

export default Login;
