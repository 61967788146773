import React, { useState } from 'react'
import Footer from './footer'
import Header from './header'
import longArrow from "./../../assets/images/longArrowWhite.svg";
import Rotate from './rotate'
import ceo from "./../../assets/images/boards/Ugo Peters.png";
import gov from "./../../assets/images/boards/Justice Emmanuel.png";
import prof from "./../../assets/images/boards/Prof Ndubuisi Ekekwe.png";
import pascal from "./../../assets/images/boards/paschal Okwundu.png";
import sufian from "./../../assets/images/boards/owolabi.jpeg";
import amby from "./../../assets/images/boards/Amby Uche.png";
import linus from "./../../assets/images/boards/Linus Caleb.png";
import Akinjide from "./../../assets/images/boards/Akinjide Fagbemi.png";
import tayo from "./../../assets/images/boards/Temitayo Gbadebo..png";
import Austin from "./../../assets/images/boards/Austin Igwe.png";
import Adiukwu from "./../../assets/images/boards/Chris Adiukwu.jpeg";

import sakeenah from "./../../assets/images/boards/sakeenah.jpg"


function Team() {
    const [color, setColor] = useState("#fff")
    const directors = [
        {
            name: "   Justice Emmanuel Fagbenle (rtd)",
            position: "Chairman - Corporate Governance / Legal Advisory",
            image: gov
        },
        {
            name: "Mr Sufian Owolabi Abdulkarim",
            position: "SEC Advisor",
            image: sufian
        },
        {
            name: "Prof Ndubuisi Ekekwe",
            position: " Technical & Entrepreneurship Advisory",
            image: prof
        }
        ,
        {
            name: "Paschal Okwundu",
            position: " Growth-Hacking Advisory",
            image: pascal
        },

        
    ]


    const team = [
        {
            name: "Ugo Peters",
            position: " CEO",
            image: ceo
        },
        {
            name: "Austin Igwe",
            position: "Go-to-Market Advisory",
            image: Austin
        },
        {
            name: "Akinjide Fagbemi",
            position: "   Real Estate Operations",
            image: Akinjide
        },
        {
            name: "Chris Adiukwu",
            position: "   GM, REICo",
            image: Adiukwu
        },
{
            name: "Sakeenah Yahaya Ibrahim",
            position: " GM Realtors First",
            image: sakeenah
        } ,
        {
            name: "Amby Uche",
            position: "   Admin & project management ",
            image: amby
        }
        ,
        {
            name: "Linus Caleb",
            position: "   Brand & web consultant",
            image: linus
        }, {
            name: "Temitayo gbadebo",
            position: "   CTO",
            image: tayo
        }
    ]
    return (
        <div className="font-family bg-white">
            <Header />

            <div className='bg-[#111920] text-white lg:py-20 py-16 overflow-hidden px-5 lg:px-0'>
                <div className='content flex justify-between items-center relative'>
                    <div className='lg:w-[60%] w-[80%] font-medium lg:text-4xl text-2xl lg:leading-[60px]'>
                        <h1>HXAFRICA is managed by an amazing group of professionals.</h1>
                    </div>
                    <div className='lg:w-[40%] w-[20%] absolute lg:relative -right-8 justify-center items-center flex'>
                        <div className="flex justify-center items-center">

                            <img src={longArrow} alt="arrow" className="absolute" />
                            <div className=" turn text-white">
                                <Rotate color={color} />

                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div>
                <div className="bg-white py-16 px-5 lg:px-32" id="board">
                    <div className="content">
                        <div className="flex flex-col items-center font-roboto">
                            <h1 className=" font-normal text-[#111920] text-3xl lg:text-4xl mb-3 text-center">
                                Meet Our Board Of <br /> Directors/Advisors
                            </h1>

                        </div>
                        <div className="py-10 flex flex-wrap justify-around">
                            {directors?.map((director, index) => (
                                <div key={index} className="flex flex-col items-center w-full border lg:w-2/6 rounded-3xl p-7 mb-10">
                                    <div className="border-5 mb-3 w-44 h-44 rounded-full bg-white">
                                        <img
                                            src={director.image}
                                            alt={director.name}
                                            className="w-full h-full object-cover rounded-full bg-white"
                                        />
                                    </div>
                                    <h1 className="text-lg text-center mb-3">
                                        {director.name}
                                    </h1>
                                    <h1 className="font-normal mb-2 text-green text-center">
                                        {director.position}
                                    </h1>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="bg-white lg:py-16 lg:px-32 px-5" id="management">
                    <div className="content">
                        <div className="flex flex-col items-center font-roboto">
                            <h1 className=" font-normal text-[#111920] lg:text-4xl text-3xl mb-3 text-center">
                                Meet Our <br /> Management Team
                            </h1>
                        </div>
                        <div className="py-10 flex flex-wrap justify-around">
                            {team?.map((mate, index) => (
                                <div key={index} className="flex flex-col items-center w-full border lg:w-2/6 rounded-3xl p-7 mb-10">
                                    <div className="border-5 mb-3 w-44 h-44 rounded-full bg-white">
                                        <img
                                            src={mate.image}
                                            alt={mate.name}
                                            className="w-full h-full object-cover rounded-full bg-white"
                                        />
                                    </div>
                                    <h1 className="text-lg text-center mb-3 capitalize">
                                        {mate.name}
                                    </h1>
                                    <h1 className="font-normal mb-2 text-green text-center capitalize">
                                        {mate.position}
                                    </h1>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </div>
    )
}

export default Team