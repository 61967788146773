import React, { useEffect, useState } from 'react'
import { useGoogleLogin } from '@react-oauth/google';
import axios from "axios";
import google from "../assets/images/google.svg";
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";



function GoogleAuth({ nextPage, title }) {
    const [user, setUser] = useState([]);
    const [profile, setProfile] = useState([]);
    const navigate = useNavigate()
    const location = useLocation()

    const authWithGoogle = useGoogleLogin({
        onSuccess: async (codeResponse) => {
            // setUser(codeResponse)
            console.log(codeResponse);
            // alert(codeResponse.access_token)
            if (user.access_token?.length == 0) {
                alert("Please try again ")
            } else {
                setProfile(null)
                setTimeout(authenticate(codeResponse.access_token), 1000)
            }

        },
        onError: (error) => console.log('Login Failed:', error)
    });

    const authenticate = async (code) => {
        try {
            const response = await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${code}`, {
                headers: {
                    Authorization: `Bearer ${code}`,
                    Accept: 'application/json'
                }
            })
            // return await response.json()
            // const result = await response?.json()
            console.log(response.data)
            setProfile(response.data)
            // profile.name === undefined ? alert("Please try again") :
            authSignUp(response.data)
        } catch (error) {
            // Handle errors
            console.log(error);
        }

    }

    const openNumber = () => {
        localStorage.setItem("addNumber", "true")
        location.pathname === "/login" ? navigate("/sign-up") :
            nextPage()
    }

    const authSignUp = async (data) => {
        const payload = {
            email: data.email,
            name: data.name,
            google_id: data.id,
            terms: data.verified_email,
        };
        const response = await fetch(
            `${process.env.REACT_APP_MY_API_ENDPOINT}google_auth`,
            {
                method: "POST",
                body: JSON.stringify(payload),
                headers: {
                    "Content-Type": "application/json",

                    Accept: "application/json",
                },
            }
        );
        const result = await response.json();
        // console.log(result.data);

        if (result?.status === "success") {
            localStorage.setItem("user-token", result?.data.api_token);
            localStorage.setItem("user-email", result?.data.email);
            localStorage.setItem("name", result?.data.name);
            localStorage.setItem("user-name", result?.data.username);
            localStorage.setItem("user-phone", result?.data.phone);
            localStorage.setItem("user-dob", result?.data.dob);
            localStorage.setItem("user-city", result?.data.city);
            localStorage.setItem("user-state", result?.data.state);
            localStorage.setItem("user-address", result?.data.address);
            localStorage.setItem("user-id", result?.data.id);
            localStorage.setItem("bvn", result?.data.is_bvn_verified);
            localStorage.setItem("role", result?.data.role);
            toast.success(`${result.message}`, {
                position: "top-right",
                autoClose: 300,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            if (result?.message === "Registered successfully") {
                openNumber()
            }
            else if (result?.message === "Logged in successfully") {
                navigate("/dashboard")
            } else {
                console.log(result?.message)
            }
        } else {
            toast.error(`${result.data}`, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                // toastId: "customId",
            });
            toast.error(`${result.message}`, {
                position: "top-right",
                autoClose: 300,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    };

    return (
        <div className='font-family'>
            <div className="bg-[#000A28] mb-5 font-normal text-sm flex justify-center items-center rounded-xl text-white p-3 font-body cursor-pointer" onClick={authWithGoogle}>
                <img src={google} alt="google icon" className="mr-2" />
                <h1>{title}</h1>
            </div>
        </div>
    )
}

export default GoogleAuth