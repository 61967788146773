import React, { useEffect, useState } from "react";
import Header from "../../../components/admin_components/Admin_header";
import SideBar from "../../../components/admin_components/SideBar";
import MarchantsList from "../../../components/admin_components/MarchantsList";
import CreateMarchant from "../../../components/admin_components/CreateMerchant";
import SubLayout from "../../../components/admin_components/layouts/SubLayout";

function Marchants() {
  const [merchants, setMerchants] = useState();
  async function fetchMerchants() {
    const token = localStorage.getItem("user-token");
    // e.preventDefault();
    const response = await fetch(
      `${process.env.REACT_APP_MY_API_ENDPOINT}admin/fetch_merchants`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();
    //console.log(result.data);
    //alert(result.data.name);
    setMerchants(result?.data);
  }
  useEffect(() => {
    fetchMerchants();
    window.scrollTo(0, 0);
  }, []);

  return (
    <SubLayout title={"Merchants"}>
        <CreateMarchant />
        <MarchantsList
          merchants={merchants}
          fetchMerchants={fetchMerchants}
        />
    </SubLayout>
  );
}

export default Marchants;
