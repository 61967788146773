import React, { useEffect, useState } from "react";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";
import search from "../../../../assets/images/Small.svg";
import avater from "../../../../assets/images/Avatar.svg";
import fromTo from "../../../../assets/images/fromTo.svg";
import calender from "../../../../assets/images/Calendar.svg";
import { NavLink, Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as CurrencyFormat from "react-currency-format";
import moment from "moment";
import Datepicker from "react-tailwindcss-datepicker";


function RevenueList({ charges }) {
    const [products, setProducts] = useState()
    const [allProducts, setAllProducts] = useState()
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())

    // useEffect(() => {
    //     axios.get("https://6425861c7ac292e3cf031b34.mockapi.io/product").then(response => {
    //         console.log(response.data);
    //         setProducts(response.data);
    //         setAllProducts(response.data);
    //         setStartDate(products[6].createdAt)
    //         setEndDate(products[4].createdAt)
    //     })
    // }, []);
    const [searchTerm, setSearchTerm] = useState("");
    const [filter, setFilter] = useState("All");
    const [select, setSelect] = useState(false);
    const [sort, setSort] = useState(false);
    // const [type, setType] = useState("new")
    // const [userId, setUserId] = useState();
    let filtered = allProducts?.filter((product) => {
        let productDate = new Date(product.createdAt)
        return productDate >= startDate && productDate <= endDate
    })
    const filters = () => {
        setProducts(filtered)
    }

    const [value, setValue] = useState([{
        startDate: null,
        endDate: null
    },
    ]);


    const [picker, setPicker] = useState(false)
    const handleValueChange = (newValue) => {
        console.log("newValue:", newValue);
        setValue(newValue);
        setPicker(true)
        setFilter("All");

    }

    return (
        <>
            <div className="flex justify-between my-6">
                <div className="flex items-center">
                    <div className="border-2 w-44 mr-3 bg-white rounded-lg relative cursor-pointer">
                        <div
                            className="w-full flex justify-between items-center px-4 py-3 text-sm text-sort"
                            onClick={() => {
                                setSelect(!select)
                            }}
                        >
                            <h1 className="truncate">
                                Sort By:{" "}
                                <span className="font-semibold text-dark ">{filter}</span>
                            </h1>

                            {select ? <FaAngleDown /> : <FaAngleRight />}
                        </div>
                        <div
                            className={`absolute shadow-lg rounded-b-lg top-full w-[250px] bg-white text-sm text-sort ${select ? "visible" : "invisible"}`}>
                            <div
                                className="border-b px-4 py-3 cursor-pointer hover:bg-welcome"
                                onClick={() => {
                                    setSearchTerm("");
                                    setFilter("All");
                                    setSort(false);
                                    setSelect(!select);
                                    setPicker(false)
                                }}
                            >
                                <h1>
                                    Sort by: <span className="font-semibold text-dark">All</span>
                                </h1>
                            </div>
                            {/* <div
                                className="border-b px-4 py-3 cursor-pointer hover:bg-welcome"
                                onClick={() => {
                                    setSort("true");
                                    setFilter("A - Z");
                                    setSelect(!select);
                                }}
                            >
                                <h1>
                                    Sort by: <span className="font-semibold text-dark">A - Z</span>
                                </h1>
                            </div> */}

                            <div
                                className="border-b px-4 py-3 cursor-pointer hover:bg-welcome"
                                onClick={() => {
                                    // setType("pullout");
                                    setSearchTerm("pullout");
                                    setFilter("Pullout");
                                    setSort(false);
                                    setSelect(!select);
                                    setPicker(false)

                                }}
                            >
                                <h1>
                                    Sort by: <span className="font-semibold text-dark">Pullout</span>
                                </h1>
                            </div>
                            <div
                                className="border-b px-4 py-3 cursor-pointer hover:bg-welcome"
                                onClick={() => {
                                    // setType("pullout");
                                    setSearchTerm("inherit");
                                    setFilter("Exchange");
                                    setSort(false);
                                    setSelect(!select);
                                    setPicker(false)

                                }}
                            >
                                <h1>
                                    Sort by: <span className="font-semibold text-dark">Exchange</span>
                                </h1>
                            </div>

                            <div
                                className="border-b px-4 py-3 cursor-pointer hover:bg-welcome"
                                onClick={() => {
                                    // setType("pullout");
                                    setSearchTerm("new");
                                    setFilter("New Investment");
                                    setSort(false);
                                    setSelect(!select);
                                    setPicker(false)

                                }}
                            >
                                <h1>
                                    Sort by: <span className="font-semibold text-dark">New Investment</span>
                                </h1>
                            </div>
                            <div
                                className="border-b px-4 py-3 cursor-pointer hover:bg-welcome"
                                onClick={() => {
                                    // setType("pullout");
                                    setSearchTerm("cancel");
                                    setFilter("Cancel Investment");
                                    setSort(false);
                                    setSelect(!select);
                                    setPicker(false)

                                }}
                            >
                                <h1>
                                    Sort by: <span className="font-semibold text-dark">Cancel Investment</span>
                                </h1>
                            </div>

                        </div>
                    </div>
                    <div className="border-2 rounded-lg w-full">
                        <Datepicker
                            value={value}
                            onChange={handleValueChange}
                            // onClick={()=> setPicker(!picker)}
                            primaryColor={"teal"}
                            inputClassName="font-normal bg-white dark:bg-white py-3 dark:py-3 outline-none dark:outline-none dark:text-black"
                            // showShortcuts={true}
                            separator={
                                `  -  `}
                            minDate={new Date("2023-03-05")}
                            maxDate={new Date().setDate(new Date().getDate() + 1)}
                        />
                    </div>
                    <div className="border-2 bg-white rounded-lg relative cursor-pointer hidden">
                        <div
                            className="w-full flex justify-between items-center px-4 text-sm font-normal"
                        // onClick={() => setSelect(!select)}
                        >
                            <div>
                                {/* <BasicDatePicker /> */}
                            </div>
                            <div className="hidden">
                                <h1 className="truncate ">
                                    2020-11-08
                                </h1>

                                <div className="px-10">
                                    <img src={fromTo} alt="inputSaparator" />
                                </div>

                                <h1 className="truncate ">
                                    2020-11-08
                                </h1>

                            </div>

                            <div className="pl-10">
                                <img src={calender} alt="calender icon" />
                            </div>
                        </div>

                    </div>

                </div>

                <div className="border-2 bg-white rounded-lg flex items-center px-5 justify-between w-411">
                    <input
                        type="search"
                        placeholder="Search Transaction ID"
                        className="outline-none font-normal text-sm w-full py-2"
                        onChange={(event) => {
                            setSearchTerm(event.target.value)
                            setPicker(false)
                        }}
                    />
                    <img src={search} alt="search" />
                </div>
            </div>


            {/* Test */}

            {/* <div>
                <div>
                    {value?.startDate} and  {value?.endDate}

                </div>
                <table>
                    <thead>
                        <tr>
                            <th>Car</th>
                            <th>number {moment(startDate).format("MMM DD, yyy")}</th>
                            <th>date  {moment(endDate).format("MMM DD, yyy")}</th>
                        </tr>

                    </thead>
                    <tbody>
                        {products?.filter(pro => {
                            let productDate = pro.createdAt
                            if (productDate >= startDate && productDate <= endDate) {
                                return pro
                            }
                        }).map((product => {
                            return (
                                <tr key={product.id}>
                                    <td>{product.name}</td>
                                    <td>{product.id}</td>
                                    <td>
                                        {moment(product.createdAt).format("MMM DD, yyyy")}
                                    </td>
                                </tr>
                            )
                        }))}


                        <button onClick={() => alert(value)} className="border p-2">alert</button>
                    </tbody>


                </table>
            </div> */}





            <div className="rounded-lg bg-white mt-2 pb-10">
                <div className="py-7 px-9 font-medium border-b cursor-pointer">
                    <h1 className="">
                        <span className="text-grayy text-sm mr-2">Transaction Activities</span>
                    </h1>
                </div>
                <div className="overflow-auto">
                    <table className="w-full table-auto">
                        <thead className="">
                            <tr className="text-left bg-bar">
                                <th className="py-3 pr-7 text-mobile-nav font-medium text-xs whitespace-nowrap pl-9">
                                    Transaction ID
                                </th>
                                <th className="py-3 pr-7 pl-3 text-mobile-nav font-medium text-xs whitespace-nowrap">
                                    Investor Name
                                </th>
                                <th className="py-3 pr-7 text-mobile-nav font-medium text-xs whitespace-nowrap">
                                    Transaction Type (%)
                                </th>
                                <th className="py-3 pr-7 text-mobile-nav font-medium text-xs whitespace-nowrap">
                                    Charges Amount
                                </th>
                                {/* <th className="py-3 pr-7 text-mobile-nav font-medium text-xs whitespace-nowrap">
                  Earnings
                </th> */}
                                <th className="py-3 pr-7 text-mobile-nav font-medium text-xs whitespace-nowrap">
                                    Investment Amount
                                </th>
                                <th className="py-3 pr-7 text-mobile-nav font-medium text-xs whitespace-nowrap">
                                    Total Transaction Amount
                                </th>
                                <th className="py-3 pr-7 text-mobile-nav font-medium text-xs whitespace-nowrap">
                                    Date
                                </th>
                            </tr>
                        </thead>
                        {charges?.filter((val) => {
                            let transactionDate = val.created_at
                            if (searchTerm === "") {
                                return val;
                            } else if (
                                val.transaction_id?.toLowerCase().includes(searchTerm.toLowerCase())
                            ) {
                                return val;
                            } else if (
                                val.type?.toLowerCase().includes(searchTerm.toLowerCase())
                            ) {
                                return val;
                            }
                        }).sort((a, b) => (a.created_at > b.created_at ? -1 : 1)).map(charge => (
                            <tr className={`border-b font-inter ${picker ? "hidden" : "visible"}`} key={charge.id}>
                                <td className="py-8 pl-9">
                                    <h1 className="font-normal text-deep text-xs">
                                        {charge.transaction_id}
                                    </h1>
                                </td>
                                <td className="py-8 px-3">
                                    <h1 className="font-normal truncate text-deep text-xs">
                                        {charge.user.name}
                                    </h1>
                                </td>
                                <td className="py-8 flex pr-2">
                                    <h1 className={`font-normal text-xs w-auto rounded-2xl px-[10px] py-[2px] truncate ${charge.type === "new_investment" ? "text-[#027A48] bg-[#ECFDF3]" : charge.type === "cancel_investment" ? "text-[#6941C6] bg-[#F9F5FF]" : charge.type === "pullout_investment" ? "text-[#B54708] bg-[#FFFAEB]" : "bg-[#EFF8FF] text-[#175CD3]"}`}>
                                        {charge.type === "new_investment" ? "New Investment (2%)" : charge.type === "cancel_investment" ? "Cancel Investment (5%)" : charge.type === "pullout_investment" ? "Pullout (10%)" : charge.type === "inherited_investment" ? "Exchange (1%)" : charge.type}
                                    </h1>
                                </td>
                                <td className="py-8">
                                    <h1 className="font-normal text-deep text-xs">
                                        N
                                        <CurrencyFormat
                                            value={charge.amount}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                        />
                                    </h1>
                                </td>
                                <td className="py-8">
                                    <h1 className="font-normal text-deep text-xs">
                                        N
                                        <CurrencyFormat
                                            value={charge.investment === null ? 0.00 : charge.investment.amount}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                        />
                                    </h1>
                                </td>
                                <td className="py-8">
                                    <h1 className="font-normal text-deep text-xs">
                                        N
                                        <CurrencyFormat
                                            value={charge.investment === null ? 0.00 : charge.investment.amount + charge.amount}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                        />
                                    </h1>
                                </td>
                                <td className="py-8 pr-5 whitespace-nowrap">
                                    <h1 className="font-normal text-deep text-xs">
                                        {moment(charge.created_at).format("MMM DD, yyyy - h:mma")}
                                    </h1>
                                </td>

                            </tr>
                        ))}

                        {charges?.filter((val) => {
                            let transactionDate = moment(val.created_at).format("MMM DD, yyy - h:mma")
                            if (transactionDate >= moment(value.startDate).format("MMM DD, yyy - h:mma") && transactionDate <= moment(value.endDate).format("MMM DD, yyy - h:mma")) {
                                return val
                            } else if (transactionDate === moment(value.endDate).format("MMM DD, yyy - h:mma")) {
                                return val
                            }
                        }).map(charge => (
                            <tr className={`border-b font-inter ${picker ? "visible" : "hidden"}`} key={charge.id}>

                                <td className="py-8 pl-9">
                                    <h1 className="font-normal text-deep text-xs">
                                        {charge.transaction_id}
                                    </h1>
                                </td>
                                <td className="py-8 px-3">
                                    <h1 className="font-normal truncate text-deep text-xs">
                                        {charge.user.name}
                                    </h1>
                                </td>
                                <td className="py-8 flex pr-2">
                                    <h1 className={`font-normal text-xs w-auto rounded-2xl px-[10px] py-[2px] truncate ${charge.type === "new_investment" ? "text-[#027A48] bg-[#ECFDF3]" : charge.type === "cancel_investment" ? "text-[#6941C6] bg-[#F9F5FF]" : charge.type === "pullout_investment" ? "text-[#B54708] bg-[#FFFAEB]" : "bg-[#EFF8FF] text-[#175CD3]"}`}>
                                        {charge.type === "new_investment" ? "New Investment (2%)" : charge.type === "cancel_investment" ? "Cancel Investment (5%)" : charge.type === "pullout_investment" ? "Pullout (10%)" : charge.type === "inherited_investment" ? "Exchange (1%)" : charge.type}
                                    </h1>
                                </td>
                                <td className="py-8">
                                    <h1 className="font-normal text-deep text-xs">
                                        N
                                        <CurrencyFormat
                                            value={charge.amount}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                        />
                                    </h1>
                                </td>
                                <td className="py-8">
                                    <h1 className="font-normal text-deep text-xs">
                                        N
                                        <CurrencyFormat
                                            value={charge.investment === null ? 0.00 : charge.investment.amount}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                        />
                                    </h1>
                                </td>
                                <td className="py-8">
                                    <h1 className="font-normal text-deep text-xs">
                                        N
                                        <CurrencyFormat
                                            value={charge.investment === null ? 0.00 : charge.investment.amount + charge.amount}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                        />

                                    </h1>
                                </td>
                                <td className="py-8 pr-5 whitespace-nowrap">
                                    <h1 className="font-normal text-deep text-xs">
                                        {moment(charge.created_at).format("MMM DD, yyyy - h:mma")}
                                    </h1>
                                </td>

                            </tr>
                        ))}

                    </table>
                    {/* <div className=" flex pt-20 px-7 items-center justify-between">
            <div className="border rounded-lg bg-page text-footer text-sm p-3">
              <span>Page 1 of 32</span>
            </div>
            <div className="flex justify-between w-80">
              <div className="text-backarrow bg-back rounded p-2 w-8 h-8 duration-100 cursor-pointer hover:bg-green hover:text-white flex items-center justify-center text-xs font-semibold">
                <MdArrowBackIosNew />
              </div>
              <div className="text-white bg-green rounded p-2 w-8 h-8 duration-100 cursor-pointer hover:bg-green hover:text-white flex items-center text-xs justify-center ">
                1
              </div>
              <div className="border text-dark rounded p-2 w-8 h-8 duration-100 cursor-pointer hover:bg-green hover:text-white flex items-center text-xs justify-center ">
                2
              </div>
              <div className="border text-dark rounded p-2 w-8 h-8 duration-100 cursor-pointer hover:bg-green hover:text-white flex items-center text-xs justify-center ">
                ...
              </div>
              <div className="border text-dark rounded p-2 w-8 h-8 duration-100 cursor-pointer hover:bg-green hover:text-white flex items-center text-xs justify-center ">
                9
              </div>
              <div className="border text-dark rounded p-2 w-8 h-8 duration-100 cursor-pointer hover:bg-green hover:text-white flex items-center text-xs justify-center ">
                10
              </div>
              <div className="border text-backarrow rounded p-2 w-8 h-8 duration-100 cursor-pointer hover:bg-green hover:text-white flex items-center text-xs justify-center ">
                <MdArrowForwardIos />
              </div>
            </div>
          </div> */}
                </div>
            </div>
        </>
    );
}

export default RevenueList;
