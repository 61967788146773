import React from "react";
import RelistedList from "./../../../components/admin_components/investment/RelistedList";
import InvestmentLayout from "../../../components/admin_components/layouts/investmentLayout";

function RelistedInvestments() {
  return (
    <InvestmentLayout>
      <RelistedList />
    </InvestmentLayout>
  );
}

export default RelistedInvestments;
