import React from "react";
import ApprovedList from "../../../components/admin_components/investment/ApprovedList";
import InvestmentLayout from "../../../components/admin_components/layouts/investmentLayout";

function Approved() {
  return (
    <InvestmentLayout>
      <ApprovedList />
    </InvestmentLayout>
  );
}

export default Approved;
