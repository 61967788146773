import React, { useEffect } from "react";
import InvestorList from "../../../components/admin_components/InvestorList";
import SubLayout from "../../../components/admin_components/layouts/SubLayout";

function Investors() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <SubLayout title={"Investors"}>
      <InvestorList />
    </SubLayout>
  );
}

export default Investors;
