import React from 'react'
import logoWhite from "./../../assets/images/HXafrica Logo 1.svg";
import facebook from "./../../assets/images/fb.svg";
import linkedIn from "./../../assets/images/linkedIn.svg";
import instagram from "./../../assets/images/instagram.svg";
import Community from './community';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <div>
            <Community />
            <div className="py-5 px-5 bg-[#9CFF6D] border-y border-[#111920]">
                <div className="content text-[#111920] text-center text-sm lg:text-lg ">

                    <h1>Looking to get notified on all our program?  <span className="font-semibold font-segoe underline">Click here</span></h1>
                </div>
            </div>
            <div className="bg-hxafrica lg:px-24 px-5 pt-20 pb-10">
                <div className="content">
                    <div className="flex gap-10 flex-col lg:flex-row">
                        <div className=" flex lg:block items-center justify-between mb-10 lg:mb-0">
                            <img src={logoWhite} alt="HXafrica" />
                            <div className="flex justify-between items-center lg:w-36 lg:pt-4">
                                <a
                                    href="https://www.facebook.com/housingexchange.ng?mibextid=ZbWKwL"
                                    target="_blank" rel="noreferrer"
                                >
                                    <img src={facebook} alt="facebook" className='px-1.5' />
                                </a>
                                <a href="https://www.linkedin.com/company/hxafrica-housingexchange-ng-ltd/" target="_blank" rel="noreferrer">
                                    <img src={linkedIn} alt="LinkedIn" className='px-1.5' />
                                </a>
                                {/* <img src={whatsapp} alt="whatsapp" /> */}
                                <a href="https://instagram.com/official_hxafrica?igshid=Yzg5MTU1MDY=" target='_blank' rel="noreferrer">
                                    <img src={instagram} alt="instagram" className='px-1.5' />
                                </a>
                            </div>
                        </div>
                        <div className=" flex-grow gap-5 flex flex-wrap justify-between lg:justify-end text-white">
                            <div className='mr-10 lg:mr-0'>
                                <h1 className="text-lightgreen font-bold text-lg mb-5">
                                    Product
                                </h1>
                                <div>
                                    <Link to="/login">
                                        <h1 className="font-normal mb-2 ">HxAfrica</h1>
                                    </Link>
                                </div>
                            </div>
                            <div>
                                <h1 className="text-lightgreen font-bold text-lg mb-5">
                                    Services
                                </h1>
                                <div className="">
                                    <a href="https://brokerage.hxafrica.com/" target="_blank" rel="noreferrer">
                                        <h1 className="font-normal mb-2 ">Brokerage</h1>{" "}
                                    </a>
                                    <a href="https://cloverhedera.hxafrica.com/" target="_blank" rel="noreferrer">
                                        <h1 className="font-normal mb-2 ">Development</h1>
                                    </a>
                                   
                                    <a href="https://hxhubb.hxafrica.com/" target="_blank" rel="noreferrer">
                                        <h1 className="font-normal mb-2 ">Start-up Business <br /> Development </h1>
                                    </a>
                                </div>
                            </div>
                            <div className="my-10 md:my-0 w-full md:w-auto">
                                <h1 className="text-lightgreen font-bold text-lg mb-5">
                                    About Us
                                </h1>
                                <div className="">
                                    <Link to="/about">
                                        <h1 className="font-normal mb-2 ">About the company</h1>
                                    </Link>
                                    <Link to="/team">
                                        <h1 className="font-normal mb-2 ">The Management team</h1>{" "}
                                    </Link>
                                    <Link to="/team">
                                        <h1 className="font-normal mb-2 ">
                                            The Board of Directors
                                        </h1>
                                    </Link>
                                    <Link to="/terms">
                                        <h1 className="font-normal mb-2 ">
                                            Terms and Conditions
                                        </h1>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-copyright py-2.5 mt-16 text-center text-white text-sm lg:text-base">
                        <h1>
                            © 2022 Housing Exchange - HX Africa. <br className="lg:hidden" />
                            All rights reserved.
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer