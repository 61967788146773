import React from "react";
import CreateMarchant from "../../../components/admin_components/CreateMerchant";
import PullList from "../../../components/admin_components/PullList";
import SubLayout from "../../../components/admin_components/layouts/SubLayout";

function PullFunds() {
  return (
    <SubLayout title="Merchants">
        <CreateMarchant />
        <PullList />
    </SubLayout>
  );
}

export default PullFunds;
