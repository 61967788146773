import React, { useEffect } from "react";
import MerchantActivities from "../../../components/admin_components/MerchantActivities";
import SubLayout from "../../../components/admin_components/layouts/SubLayout"

function Transactions() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <SubLayout title={"Transactions"}>
      <MerchantActivities />
    </SubLayout>
  );
}

export default Transactions;
