import React, { useContext, useEffect, useState } from "react";
import land from "../../../assets/images/rawland2.png";
import New from "../../../assets/images/new.svg";
import moment from "moment";
import relistedIn from "../../../assets/images/convert-card.svg";
import relist from "../../../assets/images/relisted.png";
import * as CurrencyFormat from "react-currency-format";
import ScaleLoader from "react-spinners/ScaleLoader";
import Inherit from "../../../components/user_components/modals/Inherit";
import Bidding from "../../../components/user_components/modals/Bidding";
import { useNavigate } from "react-router-dom";
import RelistedInvestmentTabs from "../../../components/user_components/relisted-investment-tabs";
import { AppContext } from "../../../contexts/AppContext";
import DashboardLayout from "../../../components/user_components/layouts/dashboardLayout";
import InvestmentLayout from "../../../components/user_components/layouts/investmentLayout";

function Relisted() {
  const value = useContext(AppContext)
  const userID = localStorage.getItem("user-id");
  const [relisted, setRelisted] = useState(true);
  const [posts, setPosts] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  async function fetchData() {
    const token = localStorage.getItem("user-token");
    // e.preventDefault();
    const response = await fetch(
      `${process.env.REACT_APP_MY_API_ENDPOINT}investment/fetch_relisted_investment`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();
    //console.log(result.data);
    // alert(result.data[0].duration);

    setPosts(result.data);
    if (result?.data.length === 0) {
      setRelisted(false);
      // alert(result.data);
    } else {
      setRelisted(true);
    }
    if (result?.status === "success") {
      setLoading(false);
    }
  }

  useEffect(() => {
    // activities();
    fetchData();
  }, []);

  const [joinInvest, setJoinInvest] = useState(false);
  const [placeBid, setPlaceBid] = useState(false);
  const [itemId, setItemID] = useState("");
  function productDetails(id) {
    setItemID(id);
    // alert(id);
    setJoinInvest(true);
  }
  function bid(id) {
    setItemID(id);
    // alert(id);

    setPlaceBid(true);
  }

  // onClick={() => {
  //                           inherit(post.pullout.id);
  //                         }}

  return (
    <DashboardLayout>
      {joinInvest && (
        <Inherit
          className="z-10"
          closeModal={setJoinInvest}
          itemId={itemId}
          setItemID={setItemID}
        />
      )}
      {placeBid && (
        <Bidding
          className="z-10"
          closeModal={setPlaceBid}
          itemId={itemId}
          setItemID={setItemID}
        />
      )}

      <InvestmentLayout>
        <div className="lg:hidden">
          <div className="lg:hidden py-8 px-4 bg-welcome text-dark text-lg font-semibold flex justify-between items-center">
            <h1 className="">Investments</h1>
            <button
              className="text-green text-sm font-inter"
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </button>
          </div>
          <div className=" p-5 lg:hidden">
            <div className="flex items-center ">
              <img src={relistedIn} alt="coins" className="mr-2" />
              <h1 className="text-sm text-title font-medium">
                Relisted Investments
              </h1>
            </div>
          </div>
        </div>

        <RelistedInvestmentTabs />
        <div className="lg:mb-8 pb-10 lg:pb-0  mine px-5 lg:px-0 bg-white">
          {relisted ? (
            <>
              {loading ? (
                <div className="text-center px-20 py-40">
                  <ScaleLoader color="#008E10" height={50} width={6} />
                </div>
              ) : (
                <div className="grid lg:grid-cols-3 gap-x-[14px] lg:gap-y-5">
                  {posts?.map((post) => (
                    <div
                      className="real-estate w-full"
                      key={post.id}
                    >
                      <div className="w-1/3 h-full">
                        <img
                          // src={land}
                          src={
                            post.product.image_path === ""
                              ? land
                              : post.product.image_path
                          }
                          alt="rawland"
                          className="w-full h-full object-cover rounded-2xl"
                        />
                      </div>
                      <div className="w-2/3">
                        <div className="mb-2">
                          <h1 className="!mb-0 truncate">
                            <span title={post.product.title}>
                              {post.product.title}
                            </span>
                          </h1>
                          <div className="flex items-start">
                            <h2 className="text-green text-xs font-medium mr-1">
                              {post.interest}% Interest Rate
                            </h2>
                            <img src={New} alt="new" />
                          </div>
                        </div>
                        <div className="text-tiny text-grayy mb-3">
                          <p className="!mb-0">
                            Relisted Date:{" "}
                            <span className="text-darkgray">
                              {moment(post.created_at).format("MMM DD, yyyy")}
                            </span>
                          </p>
                          <p className="">
                            Due Date -{" "}
                            <span className="text-darkgray">
                              {" "}
                              {moment(post.due_date).format("MMM DD, yyyy")}
                            </span>
                          </p>
                        </div>
                        <div className="text-grayy text-tiny bg-mainsec p-2 rounded-lg mb-3 w-48">
                          <p className="">
                            Amount:{" "}
                            <span className="text-darkgray text-xs font-medium ml-2">
                              {post.pullout === null ? (
                                "not available"
                              ) : (
                                <>
                                  {value.globalState.currency === "USD" ? "$" : value.globalState.currency === "GBP" ? "£" : value.globalState.currency === "HX" ? "Hx" : value.globalState.currency === "NGN" ? "N" : null}
                                  <CurrencyFormat
                                    value={
                                      post.pullout === null
                                        ? "not available"
                                        : (post.pullout.accumulated_amount / value.globalState.base_value).toFixed(2)
                                    }
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />
                                </>
                              )}
                            </span>
                          </p>
                        </div>
                        <div className=" w-48">
                          {post.user_id === 1 ? (
                            <button
                              className="bg-white text-green text-tiny w-full p-2 rounded-full"
                              onClick={() => {
                                productDetails(post.pullout.id);
                              }}
                            >
                              Inherit Investment
                            </button>
                          ) : (
                            <button
                              className={`bg-neutral text-white text-tiny w-full p-2 rounded-full ${post.user_id == userID && "!bg-grey"
                                }`}
                              onClick={() => {
                                post.user_id == userID
                                  ? alert(
                                    "You can't bid on your relisted investment"
                                  )
                                  : bid(post.pullout.id);
                              }}
                            >
                              Place Bid
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          ) : (
            <div className="flex items-center justify-center h-128">
              <div className="flex flex-col justify-center items-center">
                <img src={relist} alt="No relisted investment" />
                <h1 className="font-semibold text-xs text-statustext text-center mt-7">
                  No Relisted investments at this time. <br />
                  Keep Investing.
                </h1>
              </div>
            </div>
          )}
        </div>
      </InvestmentLayout>





    </DashboardLayout>
  );
}

export default Relisted;
