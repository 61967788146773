import React, { useEffect, useRef, useState } from "react";
import search from "../../assets/images/Small.svg";
import { NavLink, Link } from "react-router-dom";
import Banuser from "../../pages/admin/modals/Banuser";
import * as CurrencyFormat from "react-currency-format";
import moment from "moment";
import WithdrawalDetails from "../../pages/admin/modals/withdrawalDetails";
import { toast } from "react-toastify";
import Warning from "./modals/confimation";
import ConfirmPayout from "./modals/confirmPayout";


function PayoutRequests() {
    const [investors, setInvestors] = useState();
    const [warning, setWarning] = useState(false)
    const [id, setID] = useState()
    const [payoutID, setPayoutID] = useState()
    const [refNo, setRefNo] = useState()
    const [processing, setProcessing] = useState(false)
    const [payout, setPayout] = useState(false)
    async function fetchWithdrawal() {
        const token = localStorage.getItem("user-token");
        // e.preventDefault();
        const response = await fetch(
            `${process.env.REACT_APP_MY_API_ENDPOINT}admin/fetch_withdrawal`,
            {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    Accept: "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const result = await response.json();
        //console.log(result.data);
        // alert(result.data.name);
        setInvestors(result?.data);
    }

    useEffect(() => {
        fetchWithdrawal();
    }, []);

    const suspend = (id) => {
        setWarning(!warning)
        setID(id)
    }

    const approve = (id) => {
        setID(id)
        setPayout(!payout)
    }
    const ApprovePayout = async () => {
        const token = localStorage.getItem("user-token");
        setProcessing(true)
        try {
            const response = await fetch(`${process.env.REACT_APP_MY_API_ENDPOINT}admin/approve_withdrawal`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        id: id,
                        payout_id: payoutID
                    }),
                    headers: {
                        "Content-type": "application/json",
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const result = await response.json()
            if (result.status === "success") {
                toast.success(`${result.message}`, {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                fetchWithdrawal()
                setPayout(false)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setProcessing(false)
        }
    }


    async function suspendWithdrawal() {
        setProcessing(true)
        const token = localStorage.getItem("user-token");
        // e.preventDefault();
        try {
            const response = await fetch(
                `${process.env.REACT_APP_MY_API_ENDPOINT}admin/suspend_withdrawal`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        id: id
                    }),
                    headers: {
                        "Content-type": "application/json",
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const result = await response.json();
            if (result.status === "success") {
                toast.success(`${result.message}`, {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                fetchWithdrawal()
                setWarning(false)
            }

        } catch (error) {
            console.error(error);
        } finally {
            setProcessing(false)
        }
    }


    const [details, setDetails] = useState(false);

    const actionButton = [
        {
            title: "View details",
        },
        {
            title: "Confirm Payout"
        },
        {
            title: "Suspend"
        },
    ]
    const [searchTerm, setSearchTerm] = useState("");
    const [options, setOptions] = useState(null)
    const action = (index) => {
        options === index ? setOptions(null) : setOptions(index)
    }

    const optionRef = useRef()

    const handleClickOutside = (event) => {
        if (optionRef.current && !optionRef.current.contains(event.target)) {
            // Clicked outside the options box
            setOptions(null);
        }
    };

    const handleButtonClick = (event, index) => {
        // Prevent the click event from reaching the document
        event.stopPropagation();
        action(index);
    };
    useEffect(() => {
        // Attach the event listener when the component mounts
        document.addEventListener('click', handleClickOutside);

        // Detach the event listener when the component unmounts
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div className="flex flex-col gap-4">
            {details && (
                <WithdrawalDetails close={setDetails} investor={investors} refNo={refNo} />
            )}

            {warning && <Warning suspend={suspendWithdrawal} setWarning={setWarning} processing={processing} />}
            {payout && <ConfirmPayout close={setPayout} processing={processing} payout={ApprovePayout} setPayoutID={setPayoutID} />}
            <div className="flex justify-end">



                <div className="border-2 bg-white rounded-lg flex items-center py-1 px-5 justify-between w-411">
                    <input
                        type="search"
                        placeholder="Search by ref No."
                        className="outline-none font-normal text-sm w-full py-2"
                        onChange={(event) => setSearchTerm(event.target.value)}
                    />
                    <img src={search} alt="search" />
                </div>
            </div>

            {/* <Martabs /> */}
            <div className="flex items-center text-sm mar rounded-lg text-footer bg-white px-9">
                <Link to="/admin/payout">
                    <div
                        className={` text-dark text-base border-b-4 border-green font-medium px-1 py-2.5 hover:text-dark `}
                    >
                        <h1>All Requests </h1>
                    </div>
                </Link>
                <span className="w-7"> </span>
                <NavLink to="/admin/payout/suspended">
                    <div className="font-normal px-1 py-2.5 border-b-4 border-transparent hover:text-dark ">
                        <h1>Suspended </h1>
                    </div>
                </NavLink>
            </div>
            <div className="rounded-lg bg-white flex flex-col gap-5 pb-10">
                <div className="pt-6 px-8 text-lg text-[#111114] font-medium border-b border-[#DBDBDB]">
                    <h1 className="text-[#111114]">
                        Requests
                    </h1>
                </div>
                <div className="">
                    <table className="w-full table-auto">
                        <thead className="">
                            <tr className="text-left bg-bar">
                                <th className="py-3 text-[#111114] font-medium text-base pl-9">
                                    Ref No.
                                </th>
                                <th className="py-3 text-[#111114] font-medium text-base">
                                    Investor
                                </th>
                                <th className="py-3 text-[#111114] font-medium text-base">
                                    Type
                                </th>
                                <th className="py-3 text-[#111114] font-medium text-base">
                                    Amount
                                </th>
                                <th className="py-3 text-[#111114] font-medium text-base">
                                    Date
                                </th>
                                <th className="py-3 text-[#111114] font-medium text-base">
                                    Time
                                </th>
                                <th className="py-3 text-[#111114] font-medium text-base text-center">
                                    Status
                                </th>
                                <th className="py-3 text-[#111114] font-medium text-base text-center">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {investors
                                ?.filter((val) => {
                                    if (searchTerm === "") {
                                        return val;
                                    } else if (
                                        val.transaction_id?.toLowerCase().includes(searchTerm.toLowerCase())
                                    ) {
                                        return val;
                                    }
                                })
                                .map((investor, index) => (
                                    <tr
                                        key={index} className="border-b font-inter h-14 border-[#E4E7EC]">

                                        <td className="py-3 text-xs text-neutral pl-9">
                                            <h1>#{investor.transaction_id}</h1>
                                        </td>
                                        <td className="py-3 text-xs text-neutral">
                                            <h1>{investor.user.name}</h1>
                                        </td>
                                        <td className="py-3 text-xs text-neutral capitalize">
                                            <h1>{investor.type}</h1>
                                        </td>
                                        <td className="py-3 text-xs text-neutral">
                                            <h1 className="flex gap-2 items-center">
                                                <CurrencyFormat
                                                    value={investor.amount.toFixed(2)}
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                />
                                                <span className="text-[#72777F]">NGN</span>
                                            </h1>
                                        </td>
                                        <td className="py-3 text-xs text-neutral">
                                            <h1>
                                                {moment(investor.created_at).format("MMM DD, yyyy")}
                                            </h1>
                                        </td>
                                        <td className="py-3 text-xs text-neutral">
                                            <h1> {moment(investor.created_at).format("LT")}
                                            </h1>
                                        </td>
                                        <td className="py-3 text-xs text-neutral capitalize">
                                            <div className="flex justify-center items-center">
                                                <span className={`rounded-2xl py-0.5 px-2.5 text-sm font-medium ${investor.status === "pending" ? "bg-[#FFFAEB] text-[#B54708]" : investor.status === "suspended" ? "bg-[#FFFAEB] text-[#B54708]" : "bg-[#ECFDF3] text-[#027A48]"}`}>{investor.status}</span>
                                            </div>

                                        </td>
                                        <td className="py-3 text-xs text-neutral">
                                            <div className="flex justify-center items-center relative" ref={optionRef}>
                                                <button className="" onClick={(e) => handleButtonClick(e, index)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24" fill="none">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5441 7C12.5612 7 13.3866 6.104 13.3866 5C13.3866 3.896 12.5612 3 11.5441 3C10.5271 3 9.70166 3.896 9.70166 5C9.70166 6.104 10.5271 7 11.5441 7ZM11.5441 10C10.5271 10 9.70166 10.896 9.70166 12C9.70166 13.104 10.5271 14 11.5441 14C12.5612 14 13.3866 13.104 13.3866 12C13.3866 10.896 12.5612 10 11.5441 10ZM9.70166 19C9.70166 17.896 10.5271 17 11.5441 17C12.5612 17 13.3866 17.896 13.3866 19C13.3866 20.104 12.5612 21 11.5441 21C10.5271 21 9.70166 20.104 9.70166 19Z" fill="#343C44" />
                                                    </svg>
                                                </button>
                                                <div className={`absolute w-[147px] p-2.5 right-0 top-10 ${options === index ? "block" : "hidden"}`}>
                                                    <div className="absolute right-6 -top-1 z-20 p-4 bg-white rotate-45"></div>
                                                    <div className="relative flex flex-col z-10 bg-white shadow-[0px_22px_57px_0px_rgba(0,_0,_0,_0.10)]">
                                                        {actionButton.map((action, index) => (
                                                            <button key={index} disabled={index === 2 && investor.status !== "pending" ? true : false} className={`text-xs text-[#777E90] disabled:cursor-not-allowed font-medium text-left px-2.5 py-3 hover:text-neutral ${index === 2 && investor.status !== "pending" && "opacity-20"} `} onClick={() => index === 0 ? (setDetails(!details), setRefNo(investor.transaction_id)) : index === 2 ? suspend(investor.id) : approve(investor.id)}>{action.title}</button>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
}

export default PayoutRequests
