import React from 'react'
import { Routes, Route } from "react-router-dom";
import Investment from './Investment';
import Mine from './Mine';
import Pending from './Pending';
import Completed from './Completed';
import Relisted from './Relisted';
import MyBiddedInvestment from './myBiddedInvestment';
import Ongoing from './Ongoing';

function Index() {
  return (
    <div>
          <Routes>
              <Route path="/" element={<Investment />} />
              <Route path="/my-investment" element={<Mine />} />
              <Route path="/my-investment/pending" element={<Pending />} />
              <Route path="/my-investment/completed" element={<Completed />} />
              <Route path="/relisted-investment" element={<Relisted />} />
              <Route
                  path="/relisted-investment/my-bids"
                  element={<MyBiddedInvestment />}
              />
              <Route path="/ongoing" element={<Ongoing />} />



          </Routes>
    </div>
  )
}

export default Index