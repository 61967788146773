import React, { useContext, useEffect, useState } from "react";
import Header from "../../../components/user_components/Header";
import InvestTabs from "../../../components/user_components/InvestTabs";
import box from "../../../assets/images/Box.png";
import land from "../../../assets/images/rawland2.png";
import moment from "moment";
import dropCoin from "../../../assets/images/dropcoin.svg";
import crowd from "../../../assets/images/crowdfund.png";
import { toast } from "react-toastify";
import pendingCoin from "../../../assets/images/timer-pause.svg";
import * as CurrencyFormat from "react-currency-format";
import success from "../../../assets/images/Success Icon.svg";
import { TbLoader } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import ScaleLoader from "react-spinners/ScaleLoader";
import TopUp from "../../../components/user_components/modals/TopUp";
import InvestHeader from "../../../components/user_components/InvestHeader";
import MyInvestmentTabs from "../../../components/user_components/my-investment-tabs";
import { AppContext } from "../../../contexts/AppContext";
import DashboardLayout from "../../../components/user_components/layouts/dashboardLayout";
import InvestmentLayout from "../../../components/user_components/layouts/investmentLayout";

function Pending() {
  const value = useContext(AppContext)
  const [available, setAvailable] = useState(true);
  const [authCancel, setAuthCancel] = useState(false);
  const [isClick, setIsClick] = useState(false);
  const [proID, setProID] = useState();
  const [posts, setPosts] = useState();
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState();
  const navigate = useNavigate();

  async function fetchData() {
    const token = localStorage.getItem("user-token");
    // e.preventDefault();
    const response = await fetch(
      `${process.env.REACT_APP_MY_API_ENDPOINT}investment/fetch_pending_investment`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();
    //console.log(result.data);
    // alert(result.data[0].id);

    setPosts(result.data);
    if (result?.data.length === 0) {
      setAvailable(false);
      // alert("fetched Successfully");
    } else {
      setAvailable(true);
    }

    if (result?.status === "success") {
      setLoading(false);
    }
  }
  const [percentage, setPercentage] = useState();

  async function cancelPercentage() {
    const token = localStorage.getItem("user-token");
    const response = await fetch(
      `${process.env.REACT_APP_MY_API_ENDPOINT}investment/cancel_percentage`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();
    console.log(result?.status);
    setPercentage(result?.data);
  }

  useEffect(() => {
    // activities();
    cancelPercentage();

    fetchData();
  }, []);
  const [itemId, setItemID] = useState("");
  const [topUp, setTopUp] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [warning, setWarning] = useState(true);
  function redirect() {
    setProcessing(false);
    setCompleted(true);
    fetchData();
  }
  //   function productDetails(id) {
  //     setItemID(id);
  //     // setOpenDetails(true);
  //   }
  function joinNow(id) {
    setItemID(id);
    setTopUp(true);
  }
  async function Cancel() {
    const payLoad = {
      investment_id: proID,
    };
    const token = localStorage.getItem("user-token");
    const response = await fetch(
      `${process.env.REACT_APP_MY_API_ENDPOINT}investment/cancel_investment`,
      {
        method: "POST",
        body: JSON.stringify(payLoad),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();
    console.log(result);
    if (result?.status === "success") {
      setProcessing(true);
      setWarning(!warning);
      setTimeout(redirect, 7000);
    } else {
      if (result?.status === "error") {
        // alert(result.message);
        toast.error(`${result.message}`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }
  return (
    <DashboardLayout >
      <InvestmentLayout>
        {topUp && (
          <TopUp
            className="z-10"
            closeModal={setTopUp}
            itemId={itemId}
            setItemID={setItemID}
            setTile={setTitle}
            setAuthCancel={setAuthCancel}
          />
        )}

        <div>
          <div className="lg:hidden py-8 px-4 bg-welcome text-dark text-lg font-semibold flex justify-between items-center">
            <h1 className="">Investments</h1>
            <button
              className="text-green text-sm font-inter"
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </button>
          </div>
          <div className=" p-5 lg:hidden">
            <div className="flex items-center ">
              <img src={pendingCoin} alt="coins" className="mr-2" />
              <h1 className="text-sm text-title font-medium">
                Pending Investments
              </h1>
            </div>
          </div>
        </div>
        <MyInvestmentTabs />

        <div className="mb-8 pb-20 lg:pb-0 px-5 lg:px-0 bg-white investlists">
          {available ? (
            <>
              {loading ? (
                <div className="text-center px-20 py-40">
                  <ScaleLoader color="#008E10" height={50} width={6} />
                </div>
              ) : (
                <div className="mb-4">
                  {/* {posts?.map((pending) => (
                      <div key={pending.id} className="real-estate ">
                        <div className="mr-2  h-full w-1/3">
                          <img
                            // src={land}
                            src={
                              pending.product.image_path === ""
                                ? land
                                : pending.product.image_path
                            }
                            alt="rawland"
                            className="w-full h-full object-cover rounded-2xl"
                          />
                        </div>
                        <div className="w-2/3">
                          <div className="mb-2 flex justify-between">
                            <div className="w-4/5">
                              {" "}
                              <abbr
                                title={pending.product.title}
                                className="no-underline"
                              >
                                <h1
                                  data-tip={pending.product.title}
                                  className="!mb-0 truncate"
                                >
                                  {pending.product.title}
                                </h1>
                              </abbr>
                              <h2 className="text-green text-xs">
                                {pending.product.interest_rate}% Interest Rate
                              </h2>
                            </div>
                          </div>
                          <div className="text-tiny text-grayy mb-3">
                            <p className="!mb-0">
                              Time Frame:{" "}
                              <span className="text-darkgray">
                                {pending.duration} Days
                              </span>
                            </p>
                            <p className="">
                              Expires -{" "}
                              <span className="text-darkgray">
                                {moment(pending.due_date).format(
                                  "MMM DD, yyyy"
                                )}
                              </span>
                            </p>
                          </div>
                          <div className="text-grayy text-tiny bg-mainsec p-2 rounded-lg mb-2 w-48">
                            <p className="">
                              Property Worth{" "}
                              <span className="text-darkgray text-xs font-medium ml-2">
                                N
                                <CurrencyFormat
                                  value={pending.product.cost}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                              </span>
                            </p>
                          </div>
                          {pending.product.cost > pending.product.threshold ? (
                            <div>
                              <button
                                className="bg-white text-green text-tiny font-normal w-24 h-7 rounded-2xl"
                                onClick={() => {
                                  // Cancel(pending.id);
                                  setProID(pending.id);
                                  joinNow(pending.id);
                                }}
                              >
                                Top up
                              </button>
                            </div>
                          ) : (
                            <div>
                              <button
                                className="bg-white text-red text-tiny font-normal w-24 h-7 rounded-2xl"
                                onClick={() => {
                                  setTitle(pending.product.title);
                                  setAuthCancel(true);
                                  // Cancel(pending.id);
                                  setProID(pending.id);
                                }}
                              >
                                Cancel
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    ))} */}
                  <div className="px-4 bg-white overflow-auto">
                    <table className="w-full table-auto">
                      <thead className="">
                        <tr className="text-left bg-dashbg">
                          <th className="py-2 text-head font-semibold text-sm whitespace-nowrap pl-5 pr-28 lg:pr-0">
                            Investments
                          </th>
                          <th className="py-2 pr-7 text-head font-semibold text-sm whitespace-nowrap">
                            Duration
                          </th>
                          <th className="py-2 pr-7 text-head font-semibold text-sm whitespace-nowrap">
                            Property Worth
                          </th>
                          <th className="py-2 pr-7 text-head font-semibold text-sm whitespace-nowrap">
                            Amount Invested
                          </th>

                          <th className="py-2 pr-7 text-head font-semibold text-sm whitespace-nowrap">
                            Starts in
                          </th>
                          <th className="py-2 text-head font-semibold text-sm text-center whitespace-nowrap">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tr className="">
                        <td className="p-3"></td>
                      </tr>
                      {posts?.map((post) => (
                        <tr className="border-b" key={post.id}>
                          <td className=" py-8 text-footer font-bold text-sm flex">
                            <div className="h-12 w-12">
                              <img
                                src={
                                  post.product.image_path === ""
                                    ? crowd
                                    : post.product.image_path
                                }
                                alt="crowdfunding"
                                className="h-full w-full object-cover rounded-lg"
                              />
                            </div>

                            <div className="ml-2 ">
                              <h1 className="mb-1 truncate w-40 lg:w-36">
                                <span title={post.product.title}>
                                  {post.product.title}
                                </span>
                              </h1>
                              <h2 className="font-medium font-xs">
                                {post.product.category.product_category}
                              </h2>
                            </div>
                          </td>
                          <td className=" py-8 text-footer font-bold text-sm">
                            <h1>{post.duration} Days</h1>
                          </td>
                          <td className="py-8 text-footer font-bold text-sm">
                            <h1>
                              {value.globalState.currency === "USD" ? "$" : value.globalState.currency === "GBP" ? "£" : value.globalState.currency === "HX" ? "Hx" : value.globalState.currency === "NGN" ? "N" : null}
                              <CurrencyFormat
                                value={(post.product.cost / value.globalState.base_value).toFixed(2)}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                            </h1>
                          </td>
                          <td className="py-8 text-footer font-bold text-sm">
                            <h1>
                              {value.globalState.currency === "USD" ? "$" : value.globalState.currency === "GBP" ? "£" : value.globalState.currency === "HX" ? "Hx" : value.globalState.currency === "NGN" ? "N" : null}
                              <CurrencyFormat
                                value={(post.amount / value.globalState.base_value).toFixed(2)}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                            </h1>
                          </td>

                          <td className=" py-8 text-footer font-bold text-sm">
                            <h1>
                              {moment(post.due_date).diff(new Date(), "Days")}{" "}
                              Days
                            </h1>
                          </td>
                          <td className=" py-8">
                            <div className="flex gap-1.5 justify-center">
                              <button
                                className="bg-green text-xs text-white w-[75px] h-7 rounded-full font-medium"
                                onClick={() => {
                                  // Cancel(pending.id);
                                  setProID(post.id);
                                  joinNow(post.id);
                                }}
                              >
                                Top up
                              </button>
                              <button className="bg-red text-xs text-white w-[75px] h-7 rounded-full font-medium"
                                onClick={() => {
                                  setTitle(post.product.title);
                                  setAuthCancel(true);
                                  // Cancel(pending.id);
                                  setProID(post.id);
                                }}>
                                Cancel
                              </button>{" "}
                            </div>

                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                </div>

              )}
            </>
          ) : (
            <div className="flex flex-col items-center justify-center h-128">
              <div className="flex flex-col justify-center items-center">
                <img
                  src={box}
                  alt="No relisted investment"
                  className="-mr-10"
                />
              </div>
              <h1 className="font-semibold text-xs text-statustext text-center">
                Oh oh! You have no Pending
                <br />
                investments at this time
              </h1>
            </div>
          )}
        </div>
      </InvestmentLayout>


      {authCancel && (
        <Warning
          closeWarning={setAuthCancel}
          proID={proID}
          title={title}
          fetch={fetchData}
          Cancel={Cancel}
          warning={warning}
          processing={processing}
          completed={completed}
          percentage={percentage}
        />
      )}
    </DashboardLayout>
  );
}

export function Warning({
  closeWarning,
  proID,
  title,
  fetch,
  Cancel,
  warning,
  processing,
  completed,
  percentage,
}) {
  const navigate = useNavigate();

  //   const Cancel = () => {
  //     const payLoad = {
  //       investment_id: productID,
  //     };
  //     const token = localStorage.getItem("user-token");
  //     fetch(`${process.env.REACT_APP_MY_API_ENDPOINT}investment/cancel_investment", {
  //       method: "POST",
  //       body: JSON.stringify(payLoad),
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //         Accept: "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //       .then((response) => response.json())
  //       .then((json) => console.log(json));
  //   };
  // alert(productID);
  async function cancel() {
    const payLoad = {
      investment_id: proID,
    };
    const token = localStorage.getItem("user-token");
    const response = await fetch(
      `${process.env.REACT_APP_MY_API_ENDPOINT}investment/cancel_investment`,
      {
        method: "POST",
        body: JSON.stringify(payLoad),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();
    console.log(result);
    // if (result?.status === "success") {
    //   setProcessing(true);
    //   setWarning(!warning);
    //   setTimeout(redirect, 7000);
    // } else {
    //   if (result?.status === "error") {
    //     // alert(result.message);
    //     toast.error(`${result.message}`, {
    //       position: "top-right",
    //       autoClose: 3000,
    //       hideProgressBar: true,
    //       closeOnClick: false,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //     });
    //   }
    // }
  }

  return (
    <>
      <motion.div
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
          transition: {
            duration: 0.3,
          },
        }}
        exit={{
          opacity: 0,
          transition: {
            delay: 0.5,
          },
        }}
        className="flex items-center justify-center relative z-50"
      // onClick={() => {
      //         closeDetails(false)
      //     }}
      >
        <div
          className="fixed top-0 right-0 bottom-0 left-0 bg-overlay backdrop-blur-xs"
          onClick={() => {
            closeWarning(false);
          }}
        ></div>
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.3,
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              delay: 0.5,
            },
          }}
          className={`lg:w-128 w-11/12 bg-white rounded-xl fixed top-48 border-green p-6 text-center ${warning ? "block" : "hidden"
            } `}
        >
          <div>
            <h1 className="lg:font-bold font-semibold text-neutral text-3xl">
              Warning!
            </h1>
          </div>
          <div className="font-semibold text-sm lg:text-base text-neutral my-8">
            <p>
              You are about to cancel your investment <br />
              on <span className="text-green">{title} </span> <br /> This action
              will incur <span className="text-green">%{percentage}</span>{" "}
              charges of your Investment
            </p>
          </div>
          <div className="flex justify-between">
            <button
              className="border rounded-full text-sm lg:text-base w-36 h-10 lg:w-44 lg:h-12 text-neutral bg-dashbg"
              onClick={() => {
                closeWarning(!closeWarning);
                fetch();
              }}
            >
              No, Return
            </button>
            <button
              className="rounded-full text-sm lg:text-base w-36 h-10 lg:w-44 lg:h-12 text-dashbg bg-red"
              onClick={() => {
                Cancel();
              }}
            >
              Yes, Cancel
            </button>
          </div>
        </motion.div>

        {processing && (
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.3,
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                delay: 0.5,
              },
            }}
            className="lg:w-128 w-11/12 bg-white rounded-xl fixed top-48 border-green p-6 text-center"
          >
            <div>
              <h1 className="lg:font-bold font-semibold text-neutral text-3xl">
                Processing
              </h1>
            </div>
            <div className="font-semibold ;g:text-base text-sm text-neutral my-8">
              <p>
                Please wait while we process your Investment. <br />
                This will take few seconds.
              </p>
            </div>
            <div className="flex justify-center">
              <button className="rounded-full w-28 h-12 text-neutral flex justify-around items-center">
                <TbLoader className="animate-spin" /> Processing
              </button>
            </div>
          </motion.div>
        )}

        {completed && (
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.3,
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                delay: 0.5,
              },
            }}
            className="w-128 bg-white rounded-xl fixed top-20 border-green p-6 text-center"
          >
            <div className="flex flex-col items-center ">
              <img src={success} alt="success" className="w-28 mb-5" />
              <h1 className="font-bold text-neutral text-4xl">Success!</h1>
            </div>
            <div className="font-semibold text-base text-neutral my-8">
              <p>Your investment have been canceled</p>
            </div>
            <div className=" text-center w-11/12 mb-2 m-auto">
              <button
                className="rounded-full w-full p-2 text-white bg-green flex justify-around items-center"
                onClick={() => {
                  closeWarning(!closeWarning);
                  fetch();
                }}
              >
                Done
              </button>
            </div>
            <div className=" text-center w-11/12 m-auto">
              <button
                className="rounded-full w-full p-2 text-green border-green border flex justify-around items-center"
                onClick={() => navigate("/investment/my-investment")}
              >
                Go to My Investments
              </button>
            </div>
          </motion.div>
        )}
      </motion.div>
    </>
  );
}

export default Pending;
