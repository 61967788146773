import React, { useEffect } from "react";
import Header from "../../../components/admin_components/Admin_header";
import SideBar from "../../../components/admin_components/SideBar";
import InvestmentCard from "../../../components/admin_components/investment/InvestmentCard";
import InvestmentList from "../../../components/admin_components/investment/InvestmentList";
import InvestmentLayout from "../../../components/admin_components/layouts/investmentLayout";

function AllInvestments() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <InvestmentLayout>
        <InvestmentList />
    </InvestmentLayout>
  );
}

export default AllInvestments;
