import React, { useEffect, useState } from "react";
import pullout from "../../../assets/images/pulledout.svg";
import ongoing from "../../../assets/images/ongoingRelisted.svg";
import completed from "../../../assets/images/completedRelisted.svg";
import totalInvestment from "../../../assets/images/totalInvestment.svg";
import relistedInvestment from "../../../assets/images/relisted.svg";

function InvestmentCard() {
  const [total, setTotal] = useState();
  async function fetchTotal() {
    const token = localStorage.getItem("user-token");
    // e.preventDefault();
    const response = await fetch(
      `${process.env.REACT_APP_MY_API_ENDPOINT}admin/fetch_investment_total`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();
    //console.log(result.data);
    // alert(result.data.name);
    setTotal(result?.data);
  }
  useEffect(() => {
    fetchTotal();
  }, []);

  const investments = [{
    title: "Total Investments",
    count: total?.total_investment,
    icon: totalInvestment
  },
  {
    title: "Unapproved Investments",
    count: total?.total_pending_investments,
    icon: relistedInvestment
  },
  {
    title: "New Investments",
    count: total?.total_new_investments,
    icon: pullout
  }, {
    title: "ongoing Investments",
    count: total?.total_ongoing_investments,
    icon: ongoing
  }, {
    title: "completed Investments",
    count: total?.total_completed_investments,
    icon: completed
  }
  ]
  return (
    <div>
      <div className="bg-white py-9 px-1 rounded-lg my-5 grid grid-cols-5 gap-2 justify-between">
        {investments.map((investment, index) => (
          <div key={index} className={`flex gap-2 py-1 px-2 [h-58px] border-l border-[#DBDBDB] ${index === 0 && "border-none"}`}>
            <div className="shrink-0">
              <img src={investment.icon} alt="total investment" />
            </div>
            <div>
              <h1 title={investment.title} className="text-earnings font-medium text-xs truncate capitalize">
                {investment.title}
              </h1>
              <h1 className="text-dark font-medium text-2xl">
                {investment?.count}
              </h1>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default InvestmentCard;
