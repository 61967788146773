import React from 'react'
import SubLayout from './SubLayout'
import InvestmentCard from '../investment/InvestmentCard'

function InvestmentLayout({ children }) {
    return (
        <SubLayout title={"Investments"}>
            <InvestmentCard />
            <div>
                {children}
            </div>
        </SubLayout>
    )
}

export default InvestmentLayout
