
import Verification from "./Verification";
import Setup from "./Setup";
import UserDetails from "./UserDetails";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/HXafrica Logo 1.svg";



function Auth() {
  // console.log(process.env.REACT_APP_MY_API_ENDPOINT);
  const addNumber = localStorage.getItem("addNumber")

  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({
    email: "",
    phone: "",
    password: "",
    terms: "",
    confirmPassword: "",
    otp: "",
    name: "",
    state: "",
    city: "",
    address: "",
    dob: "",
  });


  const FormTitles = ["UserDetails", "Verification", "Setup"];

  const PageView = () => {
    if (step === 0) {
      return (
        <UserDetails
          formData={formData}
          nextPage={nextPage}
          setFormData={setFormData}
        //   signup={signup}
        />
      );
    } else if (step === 1) {
      return (
        <Verification
          formData={formData}
          nextPage={nextPage}
          setFormData={setFormData}
        />
      );
    } else {
      return (
        <Setup
          formData={formData}
          nextPage={nextPage}
          setFormData={setFormData}
        />
      );
    }
  };

  const nextPage = () => {
    setStep((currentPage) => currentPage + 1);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    addNumber === "true" && nextPage()
  }, []);
  return (
    <div className="font-family">

      {/* <Tab /> */}
      <div className="lg:flex justify-between bg-white 2xl:h-screen">
        <div className=" bg-[url('../src/assets/images/hxOnboard.jpg')] relative before:bg-green before:mix-blend-hard-light before:absolute before:top-0 before:left-0 before:bottom-0 before:right-0 before:content-[' '] before:-z-10 z-10 lg:w-1/2 bg-cover bg-center hidden lg:flex items-center">
          <div className="absolute top-0 left-0 pt-[77.49px] pl-5 xl:pl-[90px]">
            <Link to="/">
              <img src={logo} className="" alt="Logo" />
            </Link>

          </div>
          <div className="text-white px-5 xl:px-[90px]">
            <h1 className="font-bold text-[80px] font-body">Real Estate Solutions</h1>
            <h1 className="font-normal text-2xl">Solving the perennial problems that plagues the real estate industry; With our block-chain powered technology</h1> {step}
          </div>
        </div>
        <div className="lg:px-24 md:px-24 px-5 lg:pt-20 pt-14 pb-24 w-full lg:w-1/2">
          {PageView()}
          <div className="lg:w-80 mb-16">
            {/* <button
              type="submit"
              className="bg-green text-white w-full p-3 rounded-xl mt-6 font-medium"
              id="button"
              onClick={() => {
                if (step === FormTitles.length - 1) {
                  create();
                } else if (step === FormTitles.length - 2) {
                  verifyOTP();
                  console.log("OTP verified");
                  nextPage();
                } else {
                  signup();
                  nextPage();
                }
              }}
            >
              {step === FormTitles.length - 1 ? "Create Account" : "Next"}
            </button> */}
          </div>
          <div></div>
        </div>

      </div>
    </div>
  );
}

export default Auth;
