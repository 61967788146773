import { motion } from "framer-motion";
import { useState } from "react";
import { TbLoader } from "react-icons/tb";

function Warning({ suspend, setWarning, processing }) {
    return (
        <>
            <motion.div
                initial={{
                    opacity: 0,
                }}
                animate={{
                    opacity: 1,
                    transition: {
                        duration: 0.3,
                    },
                }}
                exit={{
                    opacity: 0,
                    transition: {
                        delay: 0.5,
                    },
                }}
                className="flex items-center justify-center absolute top-0 left-0 right-0 z-50"
            // onClick={() => {
            //         closeDetails(false)
            //     }}
            >
                <div
                    className="fixed top-0 right-0 bottom-0 left-0 bg-overlay backdrop-blur-xs"
                    onClick={() => {
                        setWarning(false);
                    }}
                ></div>
                <motion.div
                    initial={{
                        opacity: 0,
                    }}
                    animate={{
                        opacity: 1,
                        transition: {
                            duration: 0.3,
                        },
                    }}
                    exit={{
                        opacity: 0,
                        transition: {
                            delay: 0.5,
                        },
                    }}
                    className={`lg:w-128 w-11/12 bg-white rounded-xl fixed top-48 border-green p-6 text-center `}
                >
                    {processing ?
                        <>
                            <div>
                                <h1 className="font-bold text-neutral text-3xl">Processing</h1>
                            </div>
                            <div className="font-semibold text-base text-neutral my-8">
                                <p>
                                    Please wait, your request is processing. This will take few seconds.
                                </p>
                            </div>
                            <div className="flex justify-center">
                                <button className="rounded-full w-28 h-12 text-neutral flex justify-around items-center">
                                    <TbLoader className="animate-spin duration-1000" /> Processing
                                </button>
                            </div>
                        </>
                        : <>
                            <div>
                                <h1 className="lg:font-bold font-medium text-neutral text-2xl lg:text-3xl">
                                    Warning!
                                </h1>
                            </div>
                            <div className="font-semibold lg:text-base text-xs text-neutral my-8">
                                <p>
                                    Are you sure you want to suspend <br /> this withdrawal request?
                                </p>
                            </div>
                            <div className="flex justify-between">
                                <button
                                    className="border rounded-full lg:w-44 h-12 w-40 text-neutral bg-dashbg text-sm lg:text-base"
                                    onClick={() => {
                                        setWarning(false);
                                    }}
                                >
                                    No, Cancel
                                </button>
                                <button
                                    className="rounded-full w-40 lg:w-44 h-12 text-dashbg bg-red text-sm lg:text-base"
                                    onClick={suspend}
                                >
                                    Yes, Suspend
                                </button>
                            </div>
                        </>
                    }
                </motion.div>
            </motion.div>
        </>
    );
}

export default Warning