import React, { useState, useEffect } from 'react'
import { useContext } from 'react';
import * as CurrencyFormat from "react-currency-format";

import { FaAngleDown } from "react-icons/fa";
import BeatLoader from "react-spinners/BeatLoader";
import { toast } from "react-toastify";
import { AppContext } from '../../contexts/AppContext';

function Wallet({ fetchWallet }) {
    const value = useContext(AppContext)
    const userCurrency = localStorage.getItem("currency")

    const [token, setToken] = useState();
    const [drop, setDrop] = useState(false);
    const [ngn, setNgn] = useState();
    const [currencies, setCurrencies] = useState()
    const [base, setBase] = useState(JSON.parse(localStorage.getItem("base")))
    const [baseCurrency, setBaseCurrency] = useState(() => {
        const storedObject = localStorage.getItem("baseCurrency");
        return storedObject === null || storedObject === "undefined" ? base : JSON.parse(storedObject);
    });
    // console.log(value.globalState, " appContext");
    async function wallet() {
        const token = localStorage.getItem("user-token");
        const response = await fetch(
            `${process.env.REACT_APP_MY_API_ENDPOINT}wallet/fetch_wallet`,
            {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const result = await response.json();
        setToken(result.data.token);
        setNgn(result.data.balance);
        localStorage.setItem("user-wallet", result?.data.token);
    }

    const fetchRates = async () => {
        const token = localStorage.getItem("user-token");
        const response = await fetch(`${process.env.REACT_APP_MY_API_ENDPOINT}wallet/fetch_rates`,
            {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
        const result = await response.json();
        // result.status === "success" && setCurrencies(result?.data), console.log(currencies), setBase(result.data.find((data) => data.currency === userCurrency));
        if (result.status === "success") {
            setCurrencies(result?.data);
            console.log(currencies);
            setBase(result.data.find((data) => data.currency === userCurrency));
        }

    }

    const updateCurrency = async (currency) => {
        const token = localStorage.getItem("user-token");
        const response = await fetch(`${process.env.REACT_APP_MY_API_ENDPOINT}wallet/updating_currency`,
            {
                method: "POST",
                body: JSON.stringify({
                    currency: currency
                }),
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
        const result = await response.json();
        // console.log(result.data, "base")
        if (result.status === "success") {
            toast.success(`${result.message}`, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setBaseCurrency(result.data);
        }
    }


    useEffect(() => {
        wallet();
        fetchRates();
    }, [fetchWallet]);

    useEffect(() => {
        localStorage.setItem("baseCurrency", JSON.stringify(baseCurrency))
        value.setGlobalState(baseCurrency)
    }, [baseCurrency, value.setGlobalState]);

    return (
        <div>
            <div className="flex items-center justify-between w-full lg:w-auto">
                <div className="flex items-center">
                    {/* <div>
                    <img
                      src={reictoken}
                      alt="Hx TOKEN"
                      className="lg:hidden mr-2"
                    />
                  </div> */}
                    <div>
                        <h1 className="text-tiny text-token font-semibold mb-1.5 lg:hidden">
                            Total Balance
                        </h1>
                        <h1 className="lg:text-4xl font-medium mr-4 text-dark text-4l">
                            <span>
                                {
                                    token === undefined ?
                                        < BeatLoader color="#1E2335" size={5} /> :
                                        <>
                                            {baseCurrency ?
                                                <>
                                                    {baseCurrency.currency === "NGN" && "N"}
                                                    <CurrencyFormat
                                                        value={JSON.stringify((ngn / baseCurrency.base_value).toFixed(2))}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                    <span className='ml-2'>
                                                        {baseCurrency.currency === "NGN" ? null : baseCurrency.currency}</span>

                                                </> :
                                                <>
                                                    N<CurrencyFormat
                                                        value={JSON.stringify(ngn.toFixed(2))}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </>
                                            }
                                        </>
                                }
                            </span>
                        </h1>
                    </div>
                </div>

                <div
                    className="flex items-center border rounded-full lg:px-5 px-3 lg:py-2.5 py-1.5 text-footer lg:text-xs text-xxm cursor-pointer relative"
                    onClick={() => setDrop(!drop)}
                >
                    <span className="mr-1">
                        {/* {reic ? "USD" : "NGN"} */}
                        {baseCurrency ? baseCurrency.currency : "NGN"}
                    </span>
                    <FaAngleDown />
                    <div
                        className={`absolute text-neutral right-0 lg:right-0 lg:left-0 top-28 -mt-2 rounded-xl shadow-2xl bg-dashbg text-left w-28 visible duration-300 z-50 ${drop ? "show-note !top-10" : "remove-note"
                            }`}
                    >
                        {currencies?.map((currency) => (
                            <div
                                className="arrow2 relative border-b px-4 py-2 hover:bg-mainbg rounded-t-xl "
                                key={currency.id}
                                onClick={() => {
                                    updateCurrency(currency.currency)
                                }}
                            >
                                <h1 className="text-base font-normal">{currency.currency}</h1>
                            </div>
                        ))}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Wallet