import React from 'react'
import { MdClose } from "react-icons/md";
// import bank from "./../../assets/images/bank.svg";
import { motion } from "framer-motion";

function Setup({ completeSetup, setCompleteSetUp }) {
    return (
        <div>
            <motion.div
                initial={{
                    opacity: 0,
                }}
                animate={{
                    opacity: 1,
                    transition: {
                        duration: 0.3,
                    },
                }}
                exit={{
                    opacity: 0,
                    transition: {
                        delay: 0.5,
                    },
                }}
                className="flex items-center justify-center fixed top-0 right-0 bottom-0 left-0 bg-overlay z-50 backdrop-blur-xs"
            // onClick={() => {
            //         closeDetails(false)
            //     }}
            >
                <motion.div
                    initial={{
                        scale: 0,
                    }}
                    animate={{
                        scale: 1,
                        transition: {
                            duration: 0.3,
                        },
                    }}
                    exit={{
                        scale: 0,
                        transition: {
                            delay: 0.5,
                        },
                    }}
                    className="bg-white rounded-xl border w-11/12 lg:w-2/5"
                >
                    <div className="border-b border-stroke capitalize lg:uppercase px-5 lg:px-10 py-5 lg:text-2xl text-base font-semibold flex justify-between items-center text-modal">
                        <h1 className="font-iter">Add Bank</h1>
                        <MdClose
                            className="cursor-pointer"
                            onClick={() => {
                                setCompleteSetUp(!completeSetup);
                            }}
                        />
                    </div>

                </motion.div>

            </motion.div>
        </div>
    )
}

export default Setup