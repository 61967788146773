import React from 'react'
import AdminDashboardLayout from './AdminDashboardLayout'

function SubLayout({ children, title }) {
  return (
    <AdminDashboardLayout>
      <div className='flex flex-col gap-5'>
        <div className=" bg-white p-6 rounded-lg">
          <h1 className="text-dark font-black text-3xl">{title}</h1>
        </div>
        <div className='flex flex-col gap-5'>
          {children}
        </div>
      </div>
    </AdminDashboardLayout>
  )
}

export default SubLayout
