import React from 'react'
import SubLayout from '../../../components/admin_components/layouts/SubLayout'
import SuspendedRequests from '../../../components/admin_components/suspended_requests'

function Suspended() {
    return (
        <SubLayout title={"Payout"}>
            <SuspendedRequests />
        </SubLayout>
    )
}

export default Suspended
