import React, { createContext, useState } from 'react'

export const AppContext = createContext();

export const AppContextProvider = ({children}) => {
const [globalState, setGlobalState] = useState({});

  const contextValue = {
    globalState,
    setGlobalState,
  };

  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  );
};