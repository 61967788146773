import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { TbLoader } from "react-icons/tb";

function ConfirmPayout({ close, processing, payout, setPayoutID }) {
    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(true)
    const [payoutMode, setPayoutMode] = useState([
    ])

    async function fetchPayoutOptions() {
        const token = localStorage.getItem("user-token");
        // e.preventDefault();
        setLoading(true)
        try {
            const response = await fetch(
                `${process.env.REACT_APP_MY_API_ENDPOINT}admin/payout_options`,
                {
                    method: "POST",
                    headers: {
                        "Content-type": "application/json",
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const result = await response.json();

            setPayoutMode(result?.data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchPayoutOptions()
    }, [])
    return (
        <>
            <motion.div
                initial={{
                    opacity: 0,
                }}
                animate={{
                    opacity: 1,
                    transition: {
                        duration: 0.3,
                    },
                }}
                exit={{
                    opacity: 0,
                    transition: {
                        delay: 0.5,
                    },
                }}
                className="flex items-center justify-center absolute top-0 left-0 right-0 z-50"
            // onClick={() => {
            //         closeDetails(false)
            //     }}
            >
                <div
                    className="fixed top-0 right-0 bottom-0 left-0 bg-overlay backdrop-blur-xs"
                    onClick={() => {
                        close(false);
                    }}
                ></div>
                <motion.div
                    initial={{
                        opacity: 0,
                    }}
                    animate={{
                        opacity: 1,
                        transition: {
                            duration: 0.3,
                        },
                    }}
                    exit={{
                        opacity: 0,
                        transition: {
                            delay: 0.5,
                        },
                    }}
                    className={`lg:w-[543px] w-11/12 bg-white rounded-xl fixed top-48 border-green p-8 `}
                >
                    {processing ?
                        <div className="text-center">
                            <div>
                                <h1 className="font-bold text-neutral text-3xl">Processing</h1>
                            </div>
                            <div className="font-semibold text-base text-neutral my-8">
                                <p>
                                    Please wait, your request is processing. <br /> This will take few seconds.
                                </p>
                            </div>
                            <div className="flex justify-center">
                                <button className="rounded-full w-28 h-12 text-neutral flex justify-around items-center">
                                    <TbLoader className="animate-spin duration-1000" /> Processing
                                </button>
                            </div>
                        </div>
                        : <div className="flex flex-col gap-8">
                            <div className="flex flex-col gap-4">
                                <div className=" capitalize font-bold flex justify-between items-center text-[#23262F]">
                                    <h1 className="text-[32px]">Confirm Payout request</h1>
                                    <MdClose
                                        className="cursor-pointer text-2xl"
                                        onClick={() => {
                                            close(false);
                                        }}
                                    />
                                </div>
                                <h1 className="text-[#23262F] text-base font-normal">You are about to confirm a payout, select a method for the transaction</h1>
                            </div>
                            <div className="flex flex-col gap-4">
                                {loading ?
                                    <div className="flex items-center justify-center gap-1">
                                        <TbLoader className="animate-spin duration-1000" /> Processing
                                    </div>
                                    : <>
                                        {payoutMode.map((payout, index) => (
                                            <div key={index} className="border border-[D8D8D8] flex items-center gap-3 text-[#23262F] text-xl font-medium p-[18px] rounded-lg ">
                                                <input type="radio" name='payout' className="accent-green w-6 h-6" onChange={() => {
                                                    setPayoutID(payout.id)
                                                    setDisabled(false)
                                                }} />
                                                {payout.info}
                                            </div>
                                        ))}
                                    </>}
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                                <button
                                    className="border-2 rounded-full h-12 text-neutral bg-white border-[#E6E8EC] text-sm lg:text-base"
                                    onClick={() => {
                                        close(false);
                                    }}
                                >
                                    No, Cancel
                                </button>
                                <button
                                    disabled={disabled}
                                    className="rounded-full border-2 disabled:opacity-50 disabled:cursor-not-allowed border-green h-12 text-dashbg bg-green hover:bg-green/90 text-sm lg:text-base"
                                    onClick={payout}
                                >
                                    Yes, Confirm
                                </button>
                            </div>
                        </div>
                    }
                </motion.div>
            </motion.div>
        </>
    );
}

export default ConfirmPayout