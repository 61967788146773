import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import CurrencyFormat from "react-currency-format";
import moment from "moment";

function WithdrawalDetails({ close, investor, refNo }) {
    const [comment, setComment] = useState();
    const payoutDetails = investor.filter((investor) => investor.transaction_id === refNo)
    const details = [
        // {
        //     title: "Amount Invested",
        //     detail: <span>₦
        //         <CurrencyFormat
        //             value={(10000).toFixed(2)}
        //             displayType={"text"}
        //             thousandSeparator={true}
        //         /></span>
        // },
        {
            title: "Recipient Bank",
            detail: payoutDetails[0]?.bankdata?.bank_name ?? "null"
        },
        {
            title: "Recipient Account number",
            detail: payoutDetails[0].bankdata?.account_number ?? "null"
        },
        {
            title: "Account name",
            detail: payoutDetails[0].bankdata?.account_name ?? "null"
        },
        {
            title: "Request Date/Time",
            detail: `${moment(payoutDetails[0]?.created_at).format("MMM DD, yyyy")} - ${moment(payoutDetails[0]?.created_at).format("LT")}`

        }
    ]

    useEffect(() => {
        console.log(payoutDetails)
    }, [])

    return (
        <div>
            <motion.div
                initial={{
                    opacity: 0,
                }}
                animate={{
                    opacity: 1,
                    transition: {
                        duration: 0.3,
                    },
                }}
                exit={{
                    opacity: 0,
                    transition: {
                        delay: 0.5,
                    },
                }}
                className="flex items-center justify-center fixed top-0 right-0 bottom-0 left-0 bg-overlay  backdrop-blur-sm z-30"
            ></motion.div>
            <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center z-30">
                <motion.div
                    initial={{
                        scale: 0,
                    }}
                    animate={{
                        scale: 1,
                        transition: {
                            duration: 0.3,
                        },
                    }}
                    exit={{
                        scale: 0,
                        transition: {
                            delay: 0.5,
                        },
                    }}
                    className="bg-white rounded-xl border w-2/5 m-auto z-20"
                >
                    <div className="border-b border-stroke capitalize px-10 py-5 text-2xl font-semibold flex justify-between items-center text-[#23262F]">
                        <h1>View Details</h1>
                        <MdClose
                            className="cursor-pointer"
                            onClick={() => {
                                close(false);
                            }}
                        />
                    </div>
                    <div className="flex flex-col gap-9 px-11 py-8">
                        <div className="flex flex-col gap-2.5">
                            <h1 className="text-[#5E636D] text-xl font-medium">Ref No. {payoutDetails[0]?.transaction_id}</h1>
                            <div className="border border-[#D8D8D8] bg-[#FCFCFD] flex flex-col items-center py-[14px] rounded-lg">
                                <h1 className="text-[#777E90] font-medium text-[15px]">Payout Amount</h1>
                                <h1 className="flex items-center text-green  text-[32px] font-medium">
                                    ₦
                                    <CurrencyFormat
                                        value={(payoutDetails[0]?.amount).toFixed(2)}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                    />
                                </h1>
                            </div>
                        </div>

                        <div className="flex flex-col gap-2.5">
                            <h1 className="text-[#111114] text-[15px] font-medium capitalize">Request details</h1>
                            <div className="border border-[#D8D8D8] flex flex-col bg-[#FCFCFD] gap-4 p-7 rounded-lg">
                                {details.map((detail, index) => (
                                    <div key={index} className="flex justify-between items-center">
                                        <h1 className="text-[#777E90] font-medium text-[15px]">{detail.title}</h1>
                                        <h1 className="text-[#231F20] font-medium text-[15px]">{detail.detail}</h1>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                </motion.div>
            </div>
        </div>
    );
}

export default WithdrawalDetails;
