import React, { useContext } from 'react'
import { AppContext } from '../../../contexts/AppContext'
import * as CurrencyFormat from "react-currency-format";

function CurrencyCheck() {
    const value = useContext(AppContext)


  return (
    <div>
          <div className="flex items-center">
              <span className="text-tokentext text-xl font-semibold mr-2">
                  {value.globalState.currency === "USD" ? "$" : value.globalState.currency === "GBP" ? "£" : null }1
                  {value.globalState.currency === "HX" ? "Hx" : value.globalState.currency === "NGN" ? "Hx" : null}

              </span>
              {/* <span className="text-token uppercase text-sm font-semibold mr-2">
                          {" "}
                          Hx token
                        </span> */}
              <span className="text-tokentext text-xl font-semibold">
                  {" "}
                  = N
                  <CurrencyFormat
                      value={JSON.stringify((value.globalState.currency === "NGN" ? "50000" : value.globalState.base_value))}
                      displayType={"text"}
                      thousandSeparator={true}
                  />
              </span>
          </div>
    </div>
  )
}

export default CurrencyCheck