import React from "react";
import BannedList from "../../../components/admin_components/BannedList";
import SubLayout from "../../../components/admin_components/layouts/SubLayout";

function Banned() {
  return (
    <SubLayout title={"investors"}>
      <BannedList />
    </SubLayout>
  );
}

export default Banned;
