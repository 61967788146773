import React from 'react'
import Footer from './footer'
import Header from './header'
import { AiOutlineArrowRight } from "react-icons/ai";
import services from "./../../assets/images/services.svg";
import realtors from './../../assets/images/realtors first.png'
import HXHubb from './../../assets/images/co-working-space.jpg'
import cloverhedera from './../../assets/images/cloverhedera.png'




function Services() {

    const service = [
        {
            title: "Brokerage",
            desc: "Connecting Real Estate Buyers with Sellers.",
            link: "https://brokerage.hxafrica.com/",
            src: realtors
        },
        {
            title: "Development",
            desc: "Real Estate Consultancy.",
            link: "https://cloverhedera.hxafrica.com/",
            src: cloverhedera
        },
        
        {
            title: "Start-up Business Development ",
            desc: "Beyond a Co-working Space.",
            link: "https://hxhubb.hxafrica.com/",
            src: HXHubb
        }
    ]
    return (
        <div className='font-family bg-white'>
            <Header />

            <div className="py-10 lg:px-36 px-5 " id="service">
                <div className="content">
                    <div className='flex items-center'>
                        <img src={services} alt='serviceIcon' />
                        <h1 className="text-[#343C44] text-2xl font-medium ml-3">
                            Our Services{" "}
                        </h1>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 justify-between py-10 text-white">
                        {service.map((service, index) => (
                            <div key={index} className="rounded-2xl w-full">
                                <div className='h-[153px]'>
                                    <img src={service.src} alt="realtors" className='w-full h-full object-cover' />
                                </div>
                                <div className="text-[#1E1E1E]">
                                    <h1 className="font-bold font-roboto text-3xl lg:text-3xl my-3">
                                        {service.title}
                                    </h1>
                                    <h1 className="font-normal text-base lg:text-xl mb-4">
                                        {service.desc}
                                    </h1>

                                </div>
                                <a href={service.link} target="_blank" rel="noreferrer">
                                    <button className="flex px-7 my-7 py-3 font-inter bg-[#1E1E1E] items-center rounded-full text-xs lg:text-base">
                                        Visit Website <AiOutlineArrowRight className="ml-2" />{" "}
                                    </button>
                                </a>
                            </div>
                        ))}

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Services