import React from 'react'
import { Link, useLocation, useRoutes } from 'react-router-dom'

function MyInvestmentTabs() {
  const router = useLocation()
  const tabs = [
    {
      title: "All Investments",
      path:"/investment/my-investment",
      id:1
    },
     {
      title: "Pending",
       path:"/investment/my-investment/pending",
       id: 2
    },
    {
      title: "Completed",
      path: "/investment/my-investment/completed",
      id: 3
    },

  ]
  return (
    <div className='py-4 flex mx-5 lg:mx-0'>
          <div className='bg-[#F5F5F6] rounded-[36px] w-auto px-1.5 py-1 flex'>
            {tabs?.map(tabs => (
              <div key={tabs.id} className={`w-[101px] rounded-[47px] ${router.pathname === tabs.path ? "bg-white text-green" : "text-banner" }  font-normal text-[10px] text-center py-2`}>
                <Link to={tabs.path}>
                <h1>{tabs.title}</h1>
                </Link>
                </div>
            ))}
            

        </div>
    </div>
  )
}

export default MyInvestmentTabs