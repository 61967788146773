import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Overview from "./overview";
import RevenueList from "./revenueList";
import SubLayout from "../../../../components/admin_components/layouts/SubLayout";

function Revenue() {
    const [charges, setCharges] = useState();
    const [revenue, setRevenue] = useState();
    const [totalPercentage, setTotalPercentage] = useState();

    async function fetchCharges() {
        const token = localStorage.getItem("user-token");
        const response = await fetch(
            `${process.env.REACT_APP_MY_API_ENDPOINT}fetch_system_charge`,
            {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    Accept: "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const result = await response.json();
        console.log(result.data);
        setCharges(result?.data[0]);
        setRevenue(result?.data)
        var startDate = "2023-03-23";
        var endDate = "2023-03-23";
        var range = charges?.filter(function (obj) {
            return obj.created_at >= startDate && obj.created_at <= endDate;
        });
        console.log(range);
    }
    useEffect(() => {
        fetchCharges();
        window.scrollTo(0, 0);
    }, []);

    return (
        <SubLayout title={"Transactions"}>
            <div className="">
                <Overview
                    totalInvestment={revenue?.total_investment_amount}
                    totalTransaction={revenue?.total_transaction_amount}
                    transactions={revenue?.total_transactions_count}
                    percentage={revenue?.total_percentage}
                />

                <RevenueList
                    charges={charges}
                    fetchCharges={fetchCharges}
                />
            </div>
        </SubLayout>
    );
}

export default Revenue;
