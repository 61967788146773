import React from 'react'
import InvestHeader from '../InvestHeader'
import Tabs from '../Tab'
import InvestTabs from '../InvestTabs'
import { useLocation } from 'react-router'

function InvestmentLayout({children}) {
    const router = useLocation()
  return (
      <div className="bg-white w-full rounded-[15.38px] h-screen lg:h-auto lg:p-10">
          <InvestHeader />
          {router.pathname !== "/investment" ? 
              <InvestTabs />
              : <Tabs /> }
         
        {children}
    </div>
  )
}

export default InvestmentLayout