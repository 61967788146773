// import logo from './logo.svg';
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./auth/Login";
import Auth from "./auth/Signup";
import Dashboard from "./pages/user/dashboard";
import Details from "./components/user_components/modals/Investment_Details";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Processing from "./components/user_components/modals/ProcessingBvn";
import Investment from "./../src/pages/user/investment";
import Token from "./pages/user/token";
import AddBank from "./components/user_components/modals/AddBank";
import Notification from "./components/user_components/Notification";
import Profile from "./pages/user/settings";
import ChangePassword from "./pages/user/settings/ChangePassword";
import Activity from "./pages/user/settings/Activity";
import PrivateRoutes from "./util/PrivateRoutes";
import UseNetworkStatus from "./useNetworkStatus";
import SelectAvater from "./../src/pages/user/SelectAvater";
import Success from "./../src/pages/user/Success";
import Withdraw from "./components/user_components/modals/WithdrawToken";
import BiddersChat from "./../src/pages/user/Chats/biddersChat";

import Bids from "./pages/user/bids";
import NewInvestment from "./pages/user/investment/NewInvestments";
import AdminRoutes from "./util/AdminRoutes";
import AccountRecovery from "./auth/AccountRecovery";
import WaitingList from "./../src/pages/user/WaitingList";
import LandingPage from "./pages/website";
import ErrorPage from "./../src/pages/user/ErrorPage";
import Team from "./pages/website/team";
import Services from "./pages/website/services";
import About from "./pages/website/about";
// import Revenue from "./pages/admin/revenue";
import TermsAndCondition from "./pages/website/termsAndCondition";
import Index from "./pages/admin";
import { AppContextProvider } from "./contexts/AppContext";
import { useEffect } from "react";

function App() {
  const navigate = useNavigate()
  const location = useLocation();
  useEffect(() => {
    location.pathname === "/admin" && navigate("/admin/dashboard")
  }, [])

  return (
    <AppContextProvider>
      <div>
        {/* <UseNetworkStatus /> */}

        <Routes>
          <Route path="/sign-up" element={<Auth />} />
          <Route path="/login" element={<Login />} />
          <Route path="/account-recovery" element={<AccountRecovery />} />
          <Route path="/waitlist" element={<WaitingList />} />
          <Route path="/" element={<LandingPage />} />
          <Route path="/team" element={<Team />} />
          <Route path="/services" element={<Services />} />
          <Route path="/terms" element={<TermsAndCondition />} />
          <Route path="/about" element={<About />} />
          <Route path="*" element={<ErrorPage />} />

          <Route path="/status" element={<UseNetworkStatus />} />
          <Route element={<AdminRoutes />}>
            <Route path="/admin/*" element={<Index />} />
          </Route>

          <Route element={<PrivateRoutes />}>
            <Route path="/investment/*" element={<Investment />} />
            {/* <Route path="/investment" element={<Investment />} /> */}

            <Route path="/investment-chat" element={<BiddersChat />} />
            {/* <Route path="/investment-chat" element={<Owner />} /> */}
            <Route path="/bids" element={<Bids />} />
            <Route path="/buytoken/success" element={<Success />} />
            <Route path="/select-avater" element={<SelectAvater />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/settings" element={<Profile />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/activities" element={<Activity />} />
            <Route path="/token" element={<Token />} />
            <Route path="/withdraw" element={<Withdraw />} />

            <Route path="/details" element={<Details />} />
            <Route path="/addbank" element={<AddBank />} />
            <Route path="/bvn" element={<Processing />} />
            <Route path="/notification" element={<Notification />} />
            <Route
              path="/investment/new-investment"
              element={<NewInvestment />}
            />


            {/* <Route path="/header" element={<Header />} /> */}
          </Route>
        </Routes>
        <ToastContainer />
      </div>
    </AppContextProvider>
  );
}

export default App;
