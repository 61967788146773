import React, { useEffect, useState } from 'react'
import CurrencyFormat from 'react-currency-format';
import { FaAngleDown } from 'react-icons/fa';
import { toast } from 'react-toastify';

function CurrencyValue() {
    const [rates, setRates] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [isActive, setIsActive] = useState(true)
    const [loading, setLoading] = useState(false)

    const [rateBody, setRateBody] = useState({
        id: 0,
        base_value: 0
    })

    const fetchRates = async () => {
        const token = localStorage.getItem("user-token");
        const response = await fetch(`${process.env.REACT_APP_MY_API_ENDPOINT}admin/fetch_rates?`,
            {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
        const result = await response.json();
        if (result.status === "success") {
            setRates(result.data)
        }

    }

    const toggleChange = (index) => {
        isOpen === index ? setIsOpen(false) : setIsOpen(index)
        setIsActive(true)
    }

    const handleChange = (e, id) => {
        const value = e.target.value
        setRateBody((prevData) => ({
            ...prevData, id: id, base_value: value
        }))

        setIsActive(false)
    }

    const updateRate = async () => {
        const token = localStorage.getItem("user-token");
        setLoading(true)
        try {
            const response = await fetch(`${process.env.REACT_APP_MY_API_ENDPOINT}admin/update_rate`,
                {
                    method: "POST",
                    body: JSON.stringify(rateBody),
                    headers: {
                        "Content-type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                })
            const result = await response.json();
            if (result.status === "success") {
                toast.success(`${result.message}`, {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                toggleChange()
                fetchRates()
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false)
        }

    }
    useEffect(() => {
        fetchRates()
    }, [])
    return (
        <div className="rounded-xl bg-white border pb-20">
            <div className="border-b pt-5 pb-3 px-7 text-xl text-modal font-semibold">
                <h1>Currency Value </h1>
            </div>
            <div className="px-7 py-3">
                {Array.isArray(rates) && rates?.map((rate, index) => (
                    <div key={index} className="flex gap-2.5 text-dark font-semibold text-lg">
                        <h1 className="font-extrabold w-16">{rate.currency === "USD" ? "$" : rate.currency === "GBP" ? "£" : rate.currency === 'HX' ? 'Hx' : rate.currency === 'NGN' ? '₦' : rate.currency}{rate.currency_value}</h1>
                        <h1>= ₦
                            <CurrencyFormat
                                value={rate.base_value}
                                displayType={"text"}
                                thousandSeparator={true}
                            /></h1>
                    </div>
                ))}

            </div>
            <div className="px-7 flex flex-col gap-3 py-8">
                <h1 className="">
                    <span className="text-grayy text-sm capitalize">
                        Change Value
                    </span>{" "}
                </h1>

                {Array.isArray(rates) && rates?.map((rate, index) => (
                    <div key={index} className={`relative ${rate.currency === 'NGN' && 'hidden'}`}>
                        <div className="flex items-center justify-between box cursor-pointer relative" onClick={() => toggleChange(index)}>
                            <div className="flex items-center">
                                <span className="border-r-2 px-2 py-0 h-4 w-14 flex items-center font-bold text-navbar text-sm">
                                    {rate.currency}
                                </span>
                                <h1
                                    className="w-full outline-none px-2"
                                >
                                    <CurrencyFormat
                                        value={rate.base_value}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                    />
                                </h1>
                            </div>
                            <span className={`text-[#343C44] duration-300 ${isOpen === index ? '-rotate-90' : "rotate-0"}`}>
                                <FaAngleDown />
                            </span>


                        </div>
                        <div className={`p-4 shadow-xl rounded-xl absolute left-0 right-0 z-10 bg-white flex flex-col gap-2 ${isOpen === index ? "visible top-full duration-300" : "invisible top-20"}`}>
                            <label className="text-grayy text-xs capitalize">
                                update base value
                            </label>
                            <div className='flex gap-2 justify-between items-center'>
                                <input type="number" placeholder={"0.00"} defaultValue={rate.base_value} className='border rounded-lg p-2 text-xs outline-none' onChange={(e) => handleChange(e, rate.id)} />
                                <button className='text-link text-xs bg-green hover:bg-green/90 text-white rounded-2xl px-2 py-1 disabled:cursor-not-allowed disabled:bg-green/50' disabled={isActive} onClick={updateRate}>{loading ? "Updating..." : "Update"}</button>
                            </div>
                        </div>
                    </div>

                ))}

            </div>
        </div>
    )
}

export default CurrencyValue