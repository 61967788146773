import React, { useState, useEffect, useContext } from "react";
import dollar from "../../../assets/images/Vector.png";
import coin from "../../../assets/images/coin.png";
import widthdraw from "../../../assets/images/withdraw-token.svg";
import send from "../../../assets/images/moneysend.svg";
import status from "../../../assets/images/status-up.png";
import { motion } from "framer-motion";
import AddBank from "../../../components/user_components/modals/AddBank";
import { BankTransfer } from "../../../components/user_components/modals/BuyToken";
import Withdraw from "../../../components/user_components/modals/WithdrawToken";
import RecentActivity from "../../../components/user_components/recentActivity";
import * as CurrencyFormat from "react-currency-format";
import Processing from "../../../components/user_components/modals/ProcessingBvn";
import Details from "../../../components/user_components/modals/BuyToken";
import BeatLoader from "react-spinners/BeatLoader";
import Setup from "../../../components/user_components/modals/setup";
import Wallet from "../../../components/user_components/wallet";
import CurrencyCheck from "../../../components/user_components/atom/currencyCheck";
import { AppContext } from "../../../contexts/AppContext";
import DashboardLayout from "../../../components/user_components/layouts/dashboardLayout";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: "pre-wrap" }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

function Token() {
  const value = useContext(AppContext)
  const [isCardPay, setIsCardPay] = useState(false);
  const [card, setCard] = useState(false);
  const [isBankPay, setIsBankPay] = useState(false);
  const [card2, setCard2] = useState(false);
  const [amount, setAmount] = useState(50000);
  async function buy(e) {
    e.preventDefault();
    const email = localStorage.getItem("user-email");
    const payLoad = {
      email,
      amount: amount,
    };
    const token = localStorage.getItem("user-token");
    const response = await fetch(
      `${process.env.REACT_APP_MY_API_ENDPOINT}paystack/initialize_paystack`,
      {
        method: "POST",
        body: JSON.stringify(payLoad),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();
    localStorage.setItem("bought-amount", amount);

    //use toaster and display redirecting to paystack
    // alert(result?.data.data.authorization_url);
    console.log(result?.data.data.authorization_url);
    window.location.href = result?.data.data.authorization_url;
    return null;
  }
  //Token Withdrawal
  const [buyToken, setBuyToken] = useState(false);
  const [verifyBVN, setVerifyBVN] = useState(false);

  const [total, setTotal] = useState();
  const [success, setSuccess] = useState(false)

  async function fetchTotal() {
    // console.log(formData);
    const token = localStorage.getItem("user-token");
    // e.preventDefault();
    const response = await fetch(
      `${process.env.REACT_APP_MY_API_ENDPOINT}wallet/total_investment`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();
    console.log(result.status);
    setTotal(result.data);
    result?.status === "success" && setSuccess(true)
  }

  async function fetchData() {
    const token = localStorage.getItem("user-token");
    // e.preventDefault();
    const response = await fetch(
      `${process.env.REACT_APP_MY_API_ENDPOINT}investor/fetch_user_profile`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();
    result?.data.is_bankaccount_active
      === "1" ? setIsSetUp(true) : setIsSetUp(false)
  }

  useEffect(() => {
    fetchTotal();
    fetchData();
  }, []);

  const [withdraw, setWithdraw] = useState(false);
  const [bankID, setBankID] = useState();
  const [bank_Id, setBank_Id] = useState();
  const [bank, setBank] = useState(false);
  const [purchaseToken, setPurchaseToken] = useState(false);
  const [isSetUp, setIsSetUp] = useState(false);
  const [completeSetup, setCompleteSetUp] = useState(false)
  const checkSetup = () => {
    // isSetUp ? setBuyToken(true) : setCompleteSetUp(true)
    setBuyToken(true)
  }


  return (
    <DashboardLayout>
      {purchaseToken && (
        <Details className="z-10" closeToken={setPurchaseToken} />
      )}

      {buyToken && (
        <AddBank
          className="z-10"
          closeToken={setBuyToken}
          setWithdraw={setWithdraw}
          setVerifyBVN={setVerifyBVN}
          setBankID={setBankID}
          setBank_Id={setBank_Id}
        />
      )}
      {verifyBVN && (
        <Processing
          className="z-10"
          setVerifyBVN={setVerifyBVN}
          closeToken={setBuyToken}
        />
      )}
      {withdraw && (
        <Withdraw
          className="z-10"
          setWithdraw={setWithdraw}
          closeToken={setBuyToken}
          bankID={bankID}
          bank_Id={bank_Id}
        />
      )}
      {bank && <BankTransfer setBank={setBank} bank={bank} />}

      {completeSetup &&
        <Setup completeSetup={completeSetup} setCompleteSetUp={setCompleteSetUp} />}
      <div className="lg:hidden py-8 px-4 bg-welcome text-dark text-lg font-semibold">
        <h1 className="uppercase">my token wallet</h1>
      </div>
      <div className="">
        <div className="lg:p-10 px-4 py-5 bg-white rounded-lg mb-5 flex flex-wrap ">
          <div className="lg:w-2/5 w-full">
            <div className="items-center hidden lg:flex">
              {/* <img src={reictoken} alt="Hx TOKEN" /> */}
              <h1 className="text-base text-token font-semibold ml-2">
                Total Balance
              </h1>
            </div>
            <div className="flex items-center lg:mt-8 justify-between">
              <Wallet fetchWallet={withdraw} />
            </div>
          </div>

          <div className="px-8 items-center justify-between w-3/5 hidden lg:flex">
            <div className="pl-14 pr-4 border-l border-strokegrey ">
              <img src={status} alt="dollar-icon" className="mb-4 h-6 w-6" />
              <p className="text-earnings font-medium text-xs mb-1">
                My Investments
              </p>
              <h1 className="text-dark text-2xl font-medium">
                {success ?
                  total?.total_investment : < BeatLoader color="#1E2335" size={5} />}
              </h1>
            </div>
            <div className="px-4 border-strokegrey ">
              <img src={dollar} alt="dollar-icon" className="mb-4 h-6 w-6" />
              <p className="text-earnings font-medium text-xs mb-1">
                Total in {value.globalState.currency === "NGN" ? "HX" : value.globalState.currency}
              </p>
              <h1 className="text-dark text-2xl font-medium">
                {success ?
                  <>
                    {value.globalState.currency === "NGN" ? total?.token.toFixed(2) : (total?.NGN / value.globalState.base_value).toFixed(2)}

                  </> : < BeatLoader color="#1E2335" size={5} />}
              </h1>
            </div>
            <div className="px-4">
              <img src={coin} alt="dollar-icon" className="mb-4 h-6 w-6" />
              <p className="text-earnings font-medium text-xs mb-1">
                Naira Equivalent
              </p>
              <h1 className="text-dark text-2xl font-medium flex items-baseline">
                N{" "} {success ?
                  <CurrencyFormat
                    value={(total?.NGN).toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                  /> : < BeatLoader color="#1E2335" size={5} />}

              </h1>
            </div>
          </div>
          <div className="flex py-5 pl-3 lg:hidden">
            <h1 className="text-xs font-semibold text-black w-fit py-1">
              $1{" "}
              = N50,000
            </h1>
          </div>
        </div>
        <div className="w-full lg:flex">
          <div className="w-1/2 mr-2 hidden lg:block">
            <div className="bg-welcome p-10 rounded-lg border flex justify-between items-center">
              <p className="font-medium text-base text-dark">
                Easy Withdrawal to Local Bank
              </p>
              <div>
                <button
                  className="w-48 h-12 rounded-full bg-green text-white text-base font-medium"
                  // onClick={() => {
                  //   setBuyToken(true);
                  //   // setIsSetUp(true)
                  // }}
                  onClick={checkSetup}
                >
                  Withdraw Token
                </button>
              </div>
            </div>
            <div className="my-5">
              <motion.div
                initial={{
                  scale: 0,
                }}
                animate={{
                  scale: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                exit={{
                  scale: 0,
                  transition: {
                    delay: 0.5,
                  },
                }}
                className="bg-white rounded-xl"
              >
                <div className="border-b border-stroke px-10 py-5 font-semibold flex justify-between items-center text-dark text-lg">
                  <h1>Quick Purchase</h1>
                </div>
                <div className="py-10 px-10">
                  <div className="pb-4 flex items-center">
                    {/* <img
                        src={reictoken}
                        alt="my-investment-image"
                        className="w-8"
                      /> */}
                    <CurrencyCheck />
                    {/* <div className="flex items-center">
                        <span className="text-tokentext text-xl font-semibold mr-2">
                          $1
                        </span>
                 
                        <span className="text-tokentext text-xl font-semibold">
                          {" "}
                          = N50,000
                        </span>
                      </div> */}
                  </div>

                  <div className="py-10">
                    <p className="text-payment text-base font-normal mb-2.5">
                      Payment Method
                    </p>
                    <div className="flex justify-between ">
                      <button
                        className={`border-2 border-border rounded-lg w-1/2 h-12 text-token text-base font-semibold mr-1 hover:bg-green hover:text-dashbg duration-300 ${card && "bg-green !text-dashbg"
                          }`}
                        onClick={() => {
                          setIsCardPay(true);
                          setCard(true);
                          setCard2(false);
                          setIsBankPay(false);
                        }}
                      >
                        Card payment
                      </button>
                      <button
                        className={`border-2 border-border rounded-lg w-1/2 h-12 text-token text-base font-semibold ml-1 hover:bg-green hover:text-dashbg duration-300 ${card2 && "bg-green !text-dashbg"
                          }`}
                        onClick={() => {
                          // alert("No Bank added yet");
                          setCard(false);
                          setIsCardPay(false);
                          setCard2(true);
                          setIsBankPay(true);
                        }}
                      >
                        Bank Transfer
                      </button>
                    </div>
                  </div>

                  <div className="pt-5 pb-9">
                    <p className="text-payment text-base font-normal mb-2.5">
                      Amount
                    </p>
                    <div className="text-neutral font-bold text-lg flex items-center justify-center py-6 rounded-lg bg-mainbg relative">
                      <sup className="w-2/5 text-right">N</sup>

                      <input
                        type="number"
                        placeholder="50000"
                        className="text-neutral font-bold text-4xl w-3/5 bg-transparent outline-none"
                        onChange={(e) => setAmount(e.target.value)}
                        thousandSeparator={true}
                        defaultValue="50000"
                      />
                    </div>
                    {/* <span className="text-green text-xs">
                        {amount / 50000} Hx
                      </span> */}
                    <span className="text-green text-xs">{value.globalState.currency === "USD" ? "$" : value.globalState.currency === "GBP" ? "£" : value.globalState.currency === "HX" ? "Hx" : value.globalState.currency === "NGN" ? "Hx" : null}{(value.globalState.currency === "NGN" ? amount / 50000 : (amount / value.globalState.base_value).toFixed(2))} </span>
                  </div>

                  <div className="text-right py-8 flex justify-between items-center">
                    <div className=" flex items-center">
                      <input
                        required
                        type="checkbox"
                        className="border mr-2 checked:bg-green"
                        value="1"
                      />
                      <p className="text-sm text-footer font-medium">
                        Save method as default
                      </p>
                    </div>

                    {isCardPay ? (
                      <button
                        className="rounded-full w-44 h-12 text-dashbg bg-green"
                        onClick={buy}
                      >
                        Pay with Card
                      </button>
                    ) : isBankPay ? (
                      <button
                        className="rounded-full w-44 h-12 text-dashbg bg-green"
                        onClick={() => {
                          setBank(true);
                        }}
                      >
                        Continue
                      </button>
                    ) : (
                      <button
                        className="rounded-full w-44 h-12 text-dashbg bg-green"
                        onClick={() => {
                          alert("select Payment Method");
                        }}
                      >
                        Buy Token
                      </button>
                    )}
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
          <div className="bg-welcome px-4 py-5 lg:hidden flex justify-between mb-4">
            <button
              className="font-normal text-dashbg bg-darkButton rounded-full text-tiny flex items-center py-3 px-5"
              onClick={() => {
                setBuyToken(true);
              }}
            >
              <img src={widthdraw} alt="withdraw token icon" />{" "}
              <span className="ml-2">Withdraw Token </span>
            </button>
            <button
              className="font-normal text-dashbg bg-green rounded-full text-tiny flex items-center py-3 px-9"
              onClick={() => {
                setPurchaseToken(true);
              }}
            >
              <img src={send} alt="withdraw token icon" />{" "}
              <span className="ml-2">Buy Token </span>
            </button>
          </div>
          <div className="lg:w-1/2 lg:ml-2 font-inter">
            <ErrorBoundary>
              <RecentActivity fetchRecent={withdraw} />
            </ErrorBoundary>
          </div>
        </div>
      </div>

    </DashboardLayout>
  );
}

export default Token;
