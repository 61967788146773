import React from "react";
import CompletedList from "../../../components/admin_components/investment/CompletedList";
import InvestmentLayout from "../../../components/admin_components/layouts/investmentLayout";

function CompletedInvestment() {
  return (
    <InvestmentLayout>
      <CompletedList />
    </InvestmentLayout>
  );
}

export default CompletedInvestment;
