import React, { useContext, useEffect, useState } from "react";
import Header from "../../../components/user_components/Header";
import InvestTabs from "../../../components/user_components/InvestTabs";
import banner from "../../../assets/images/banner.png";
import land from "../../../assets/images/rawland2.png";
import { TbLoader } from "react-icons/tb";
import New from "../../../assets/images/new.svg";
import moment from "moment";
import { toast } from "react-toastify";
import relistedIn from "../../../assets/images/convert-card.svg";
import relist from "../../../assets/images/relisted.png";
import * as CurrencyFormat from "react-currency-format";
import ScaleLoader from "react-spinners/ScaleLoader";
import Inherit from "../../../components/user_components/modals/Inherit";
import Bidding from "../../../components/user_components/modals/Bidding";
import { useNavigate } from "react-router-dom";
import InvestHeader from "../../../components/user_components/InvestHeader";
import { motion } from "framer-motion";
import RelistedInvestmentTabs from "../../../components/user_components/relisted-investment-tabs";
import { AppContext } from "../../../contexts/AppContext";
import DashboardLayout from "../../../components/user_components/layouts/dashboardLayout";
import InvestmentLayout from "../../../components/user_components/layouts/investmentLayout";

function MyBiddedInvestment() {
  const value = useContext(AppContext)
  const userID = localStorage.getItem("user-id");
  const [relisted, setRelisted] = useState(true);
  const [posts, setPosts] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  async function fetchData() {
    const token = localStorage.getItem("user-token");
    // e.preventDefault();
    const response = await fetch(
      `${process.env.REACT_APP_MY_API_ENDPOINT}exchange/fetch_my_bids`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();
    //console.log(result.data);
    // alert(result.data[0].duration);

    setPosts(result.data);
    if (result?.data.length === 0) {
      setRelisted(false);
      // alert(result.data);
    } else {
      setRelisted(true);
    }
    if (result?.status === "success") {
      setLoading(false);
    }
  }

  useEffect(() => {
    // activities();
    fetchData();
  }, []);

  const [joinInvest, setJoinInvest] = useState(false);
  const [placeBid, setPlaceBid] = useState(false);
  const [itemId, setItemID] = useState("");
  function productDetails(id) {
    setItemID(id);
    // alert(id);
    setJoinInvest(true);
  }
  function bid(id) {
    setItemID(id);
    // alert(id);

    setPlaceBid(true);
  }
  const [negotiate, setNegotiate] = useState(false);
  const [available, setAvailable] = useState(true);
  localStorage.setItem("available", available);
  return (
    <DashboardLayout>
      <InvestmentLayout>
        {joinInvest && (
          <Inherit
            className="z-10"
            closeModal={setJoinInvest}
            itemId={itemId}
            setItemID={setItemID}
          />
        )}
        {placeBid && (
          <Bidding
            className="z-10"
            closeModal={setPlaceBid}
            itemId={itemId}
            setItemID={setItemID}
          />
        )}

        {negotiate && (
          <div className="fixed top-0 bottom-0 flex items-center justify-center left-0 right-0 z-30">
            <div
              className="fixed top-0 right-0 bottom-0 left-0 bg-overlay backdrop-blur-xs"
              onClick={() => {
                setNegotiate(false);
              }}
            ></div>
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                opacity: 0,
                transition: {
                  delay: 0.5,
                },
              }}
              className={`lg:w-128 w-11/12 bg-white rounded-xl absolute border-green p-6 text-center`}
            >
              <div>
                <h1 className="lg:font-bold font-semibold text-neutral text-3xl">
                  Negotiation
                </h1>
              </div>
              <div className="font-semibold lg:text-base text-sm text-neutral my-8">
                <p>
                  You will be redirected to have a chat with the investment
                  owner before placing a bid
                </p>
              </div>
              <div className="flex justify-between">
                <button
                  className="border text-sm lg:text-base rounded-full w-40 lg:w-44 h-12 text-neutral bg-dashbg"
                  onClick={() => {
                    setNegotiate(false);
                  }}
                >
                  No, Cancel
                </button>
                <button
                  className="rounded-full text-sm lg:text-base w-40 lg:w-44 h-12 text-dashbg bg-green"
                  onClick={() => {
                    navigate("/investment-chat");
                    setAvailable(true);
                  }}
                >
                  Yes, Continue
                </button>
              </div>
            </motion.div>
          </div>
        )}

        <div className="lg:hidden">
          <div className="lg:hidden py-8 px-4 bg-welcome text-dark text-lg font-semibold flex justify-between items-center">
            <h1 className="">Investments</h1>
            <button
              className="text-green text-sm font-inter"
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </button>
          </div>
          <div className=" p-5 lg:hidden">
            <div className="flex items-center ">
              <img src={relistedIn} alt="coins" className="mr-2" />
              <h1 className="text-sm text-title font-medium">
                Relisted Investments
              </h1>
            </div>
          </div>
        </div>
        <RelistedInvestmentTabs />
        <div className="lg:mb-8 pb-10 lg:pb-0  mine px-5 lg:px-0 bg-white">
          {relisted ? (
            <>
              {loading ? (
                <div className="text-center px-20 py-40">
                  <ScaleLoader color="#008E10" height={50} width={6} />
                </div>
              ) : (
                <div className="grid lg:grid-cols-3 gap-x-[14px] lg:gap-y-5">
                  {posts?.map((post) => (
                    <div
                      className="real-estate w-full "
                      key={post.id}
                    >
                      <div className="w-1/3 h-full">
                        <img
                          // src={land}
                          src={
                            post.investment.product.image_path === ""
                              ? land
                              : post.investment.product.image_path
                          }
                          alt="rawland"
                          className="w-full h-full object-cover rounded-2xl"
                        />
                      </div>
                      <div className="w-2/3">
                        <div className="mb-2">
                          <h1 className="!mb-0 truncate">
                            <span title={post.investment.product.title}>
                              {post.investment.product.title}
                            </span>
                          </h1>
                          <div className="flex items-start">
                            <h2 className="text-green text-xs font-medium mr-1">
                              {post.interest}% Interest Rate
                            </h2>
                            <img src={New} alt="new" />
                          </div>
                        </div>
                        <div className="text-tiny text-grayy mb-3">
                          <p className="!mb-0">
                            Relisted Date:{" "}
                            <span className="text-darkgray">
                              {moment(post.created_at).format("MMM DD, yyyy")}
                            </span>
                          </p>
                          <p className="">
                            Due Date -{" "}
                            <span className="text-darkgray">
                              {" "}
                              {moment(post.due_date).format("MMM DD, yyyy")}
                            </span>
                          </p>
                        </div>
                        <div className="text-grayy text-tiny bg-mainsec p-2 rounded-lg mb-3 w-48">
                          <p className="">
                            Amount:{" "}
                            <span className="text-darkgray text-xs font-medium ml-2">

                              <>
                                {value.globalState.currency === "USD" ? "$" : value.globalState.currency === "GBP" ? "£" : value.globalState.currency === "HX" ? "Hx" : value.globalState.currency === "NGN" ? "N" : null}
                                <CurrencyFormat
                                  value={
                                    post.investment.amount === null
                                      ? "not available"
                                      : (post.investment.amount / value.globalState.base_value).toFixed(2)
                                  }
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                              </>
                            </span>
                          </p>
                        </div>
                        <div className=" w-48">
                          <button
                            className={`bg-neutral text-white text-tiny w-full p-2 rounded-full`}
                            onClick={() => {
                              // setIsClick(true);
                              setNegotiate(true);
                              localStorage.setItem(
                                "investment-owner",
                                post.investment.user_id
                              );
                              // alert(post.user_id);
                            }}
                          >
                            Continue Negotiation
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          ) : (
            <div className="flex items-center justify-center h-128">
              <div className="flex flex-col justify-center items-center">
                <img src={relist} alt="No relisted investment" />
                <h1 className="font-semibold text-xs text-statustext text-center mt-7">
                  You have no pending. <br />
                  Bids at this time
                </h1>
              </div>
            </div>
          )}
        </div>
        <div className="justify-center p-10 hidden">
          <button className="border border-more font-medium rounded-full w-40 h-10 text-neutral flex justify-center items-center">
            <TbLoader className="mr-3" /> Load more
          </button>
        </div>

      </InvestmentLayout>
    </DashboardLayout>
  );
}

export default MyBiddedInvestment;
